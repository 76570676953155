export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Version"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of the upgrades available for your addon"])},
        "currentVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current version"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version de la base de données"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail des mises à jour disponibles pour votre addon"])},
        "currentVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version actuelle"])}
      }
    }
  })
}
