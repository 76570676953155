import { defineStore } from "pinia";

import {
  useResourceStore,
  toPublicStore,
} from "@/lib/pinia/use-resource-store";
import { scalingoDBClient } from "@/lib/scalingo/dbapi/client";

import { useCurrentAppStore } from "../current/app";
import { useCurrentDBStore } from "../current/db";

export const useDbPlanStore = defineStore("dbPlan", () => {
  const store = useResourceStore({
    fetchPromise: async () => {
      const appStore = useCurrentAppStore();
      const dbStore = useCurrentDBStore();

      const app = appStore.regional;
      if (!app) throw new Error("no current app");

      const addon = dbStore.addon;
      if (!addon) throw new Error("no current addon");

      const db = dbStore.database;
      if (!db) throw new Error("no current db");

      const client = await scalingoDBClient(app, addon.id);

      return client.Database.planFor(addon.id);
    },
  });

  return toPublicStore(store, {});
});
