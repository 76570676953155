export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked extensions."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
        "dashboard": {
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version (preview)"])},
          "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
        },
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No addon yet"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions liées."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
        "dashboard": {
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle version (preview)"])},
          "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
        },
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun addon"])}
      }
    }
  })
}
