<script>
import { ref, computed, defineComponent } from "vue";

import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import {
  currentDB,
  currentDBClient,
  useCurrentDBStore,
} from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "RedisPersistence",
  setup() {
    const t = i18nT(this.__i18n[0].resource); // To use i18n
    const toastsStore = useToastsStore();

    const currentDbStore = useCurrentDBStore();
    const AOFInfo = ref(voidPromiseInfo());
    const cacheInfo = ref(voidPromiseInfo());
    const RDBInfo = ref(voidPromiseInfo());

    async function enableAOF() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(db.id, "redis-aof").then(
        currentDbStore.refreshDBFeatures,
      );

      AOFInfo.value = promiseInfo(promise);

      try {
        await AOFInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("AOFEnabled.success.title"),
            message: t("AOFEnabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("AOFEnabled.error.title"),
          message: t("AOFEnabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function disableAOF() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(db.id, "redis-aof").then(
        currentDbStore.refreshDBFeatures,
      );

      AOFInfo.value = promiseInfo(promise);

      try {
        await AOFInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("AOFDisabled.success.title"),
            message: t("AOFDisabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("AOFDisabled.error.title"),
          message: t("AOFDisabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function enableCache() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(db.id, "redis-cache").then(
        currentDbStore.refreshDBFeatures,
      );

      cacheInfo.value = promiseInfo(promise);

      try {
        await cacheInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("cacheEnabled.success.title"),
            message: t("cacheEnabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("cacheEnabled.error.title"),
          message: t("cacheEnabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function disableCache() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(db.id, "redis-cache").then(
        currentDbStore.refreshDBFeatures,
      );

      cacheInfo.value = promiseInfo(promise);

      try {
        await cacheInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("cacheDisabled.success.title"),
            message: t("cacheDisabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("cacheDisabled.error.title"),
          message: t("cacheDisabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function enableRDB() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(db.id, "redis-rdb").then(
        currentDbStore.refreshDBFeatures,
      );

      RDBInfo.value = promiseInfo(promise);

      try {
        await RDBInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("RDBEnabled.success.title"),
            message: t("RDBEnabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("RDBEnabled.error.title"),
          message: t("RDBEnabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function disableRDB() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(db.id, "redis-rdb").then(
        currentDbStore.refreshDBFeatures,
      );

      RDBInfo.value = promiseInfo(promise);

      try {
        await RDBInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("RDBDisabled.success.title"),
            message: t("RDBDisabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("RDBDisabled.error.title"),
          message: t("RDBDisabled.error.message", { err: e.data.error }),
        });
      }
    }

    return {
      db: computed(() => currentDbStore.database),
      AOFInfo,
      disableAOF,
      enableAOF,
      cacheInfo,
      disableCache,
      enableCache,
      RDBInfo,
      enableRDB,
      disableRDB,
    };
  },
});
</script>

<i18n>
en:
  AOFEnabled:
    success:
      title: "AOF enabled"
      message: "AOF option was successfully enabled."
    error:
      title: "AOF activation failure"
      message: "The activation of AOF option failed: {err}"
  AOFDisabled:
    success:
      title: "AOF disabled"
      message: "AOF option was successfully disabled."
    error:
      title: "AOF deactivate failure"
      message: "The deactivate of AOF option failed: {err}"
  cacheEnabled:
    success:
      title: "Cache enabled"
      message: "Cache option was successfully enabled."
    error:
      title: "Cache activation failure"
      message: "The activation of Cache option failed: {err}"
  cacheDisabled:
    success:
      title: "Cache disabled"
      message: "Cache option was successfully disabled."
    error:
      title: "Cache deactivate failure"
      message: "The deactivate of Cache option failed: {err}"
  RDBEnabled:
    success:
      title: "RDB enabled"
      message: "RDB option was successfully enabled."
    error:
      title: "RDB activation failure"
      message: "The activation of RDB option failed: {err}"
  RDBDisabled:
    success:
      title: "RDB disabled"
      message: "RDB option was successfully disabled."
    error:
      title: "RDB deactivate failure"
      message: "The deactivate of RDB option failed: {err}"
fr:
  AOFEnabled:
    success:
      title: "AOF activé"
      message: "L'option AOF a été activée avec succès."
    error:
      title: "Échec de l'activation de l'option AOF"
      message: "L'activation de l'option AOF a échoué: {err}"
  AOFDisabled:
    success:
      title: "AOF désactivé"
      message: "L'option AOF a été désactivée avec succès."
    error:
      title: "Échec de a désactivation de AOF"
      message: "La désactivation de l'option AOF a échoué: {err}"
  cacheEnabled:
    success:
      title: "Cache activé"
      message: "L'option Cache a été activée avec succès."
    error:
      title: "Échec de l'activation de l'option Cache"
      message: "L'activation de l'option Cache a échoué: {err}"
  cacheDisabled:
    success:
      title: "Cache désactivé"
      message: "L'option Cache a été désactivée avec succès."
    error:
      title: "Échec de a désactivation de Cache"
      message: "La désactivation de l'option Cache a échoué: {err}"
  RDBEnabled:
    success:
      title: "RDB activé"
      message: "L'option RDB a été activée avec succès."
    error:
      title: "Échec de l'activation de l'option RDB"
      message: "L'activation de l'option RDB a échoué: {err}"
  RDBDisabled:
    success:
      title: "RDB désactivé"
      message: "L'option RDB a été désactivée avec succès."
    error:
      title: "Échec de a désactivation de RDB"
      message: "La désactivation de l'option RDB a échoué: {err}"
</i18n>
