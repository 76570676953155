<template>
  <ConfirmModal
    :immediate="false"
    :promiseInfo="context.info"
    :title="$t('title')"
    :weakConfirmation="true"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p>
      {{ $t("disclaimer.line1") }}
      <span class="font-bold"
        >{{ formatDateTime(context.backup.started_at) }} UTC ?</span
      >
    </p>

    <template #weakConfirmationPrompt>
      <span class="font-medium">{{ $t("disclaimer.checkbox") }}</span>
    </template>

    <InformationAlert class="mt-3">
      {{ $t("warning") }}
    </InformationAlert>

    <p class="mt-2"></p>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  components: { ConfirmModal, InformationAlert },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
  setup() {
    return { formatDateTime };
  },
});
</script>

<i18n>
en:
  title: "Restore a backup"
  disclaimer:
    line1: "Are you sure you want to restore backup from"
    checkbox: "I understand that this action will permanently delete existing data and cannot be cancelled or undone once started."
  warning: "Restoration time can vary from a few minutes to several hours, depending on the size of your database. We recommend that you do not perform any operations on your database until it has been fully restored."
fr:
  title: "Restaurer un backup"
  disclaimer:
    line1: "Êtes-vous sûr de vouloir restaurer la sauvegarde du"
    checkbox: "Je comprends que cette action effacera définitivement les données existantes et qu'elle ne pourra pas être annulée."
  warning: "Le temps de restauration peut varier de quelques minutes à plusieurs heures, en fonction de la taille de votre base de données. Nous vous recommandons de ne pas effectuer d'opérations sur votre base de données tant qu'elle n'a pas été entièrement restaurée."
</i18n>
