<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :addons="addons"
    :containerSizes="containerSizes"
    :containers="containers"
    :canActOnContainers="canActOnContainers"
    :autoscalers="autoscalers"
    :autoscalerRecommendedValue="autoscalerRecommendedValue"
    :autoscalerHandler="autoscalerHandler"
    :autoscalerActions="autoscalerActions"
    :deleteAddonCtx="deleteAddonCtx"
    :busyAddons="busyAddons"
    :cronTasks="cronTasks"
    @stopAll="stopAll"
    @restartAll="restartAll"
    @restartContainer="restartContainer"
    @scaleContainer="scaleContainer"
    @stopContainer="stopContainer"
    @resumeAddon="resumeAddon"
    @createAutoscaler="createAutoscaler"
    @editAutoscaler="editAutoscaler"
    @cancelAutoscaler="cancelAutoscaler"
    @enableAutoscaler="enableAutoscaler"
    @disableAutoscaler="disableAutoscaler"
    @destroyAutoscaler="destroyAutoscaler"
    @autoScaleFormSubmitted="(e) => autoscalerHandler.submit(e)"
    @autoscalerMetricChanged="autoscalerMetricChanged"
    @startAddonDeletion="startAddonDeletion"
    @cancelAddonDeletion="cancelAddonDeletion"
    @confirmAddonDeletion="confirmAddonDeletion"
  />
</template>

<script>
import { defineComponent, nextTick } from "vue";

import ViewComponent from "@/components/views/app/Resources.vue";
import {
  CreateAutoscalerHandler,
  EditAutoscalerHandler,
  DeleteAutoscalerHandler,
  RestartContainersHandler,
  ScaleContainersHandler,
  GetAutoscalerRecommendationHandler,
  EnableAutoscalerHandler,
  DisableAutoscalerHandler,
} from "@/lib/handlers";
import { closeCurrentModal } from "@/lib/modals";
import { voidPromiseInfo } from "@/lib/promises/info";
import { ensureAutoscalers, listAutoscalers } from "@/store/autoscalers";
import {
  listContainerSizes,
  ensureContainerSizes,
} from "@/store/container-sizes";
import { listContainers, ensureContainers } from "@/store/containers";
import { ensureCronTasks, listCronTasks } from "@/store/cron-tasks";
import { ongoingOperations } from "@/store/operations";
import { useAppAddonsStore } from "@/stores/app/addons";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "ResourcesContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      containerOperation: null,
      justRestarted: false,
      autoscalerRecommendedValue: null,
      autoscalerHandler: null,
      autoscalerActions: {},
      deleteAddonCtx: null,
      busyAddons: {},
    };
  },
  computed: {
    addonsStore() {
      return useAppAddonsStore();
    },
    toastsStore() {
      return useToastsStore();
    },
    ongoingOperations() {
      return ongoingOperations(this.$store);
    },
    canActOnContainers() {
      return !this.justRestarted && this.ongoingOperations.length === 0;
    },
    addons() {
      return {
        items: this.addonsStore.items,
        promiseInfo: this.addonsStore.promiseInfo,
      };
    },
    cronTasks() {
      return listCronTasks(this.$store);
    },
    containerSizes() {
      return listContainerSizes(this.$store, this.app.region);
    },
    containers() {
      return listContainers(this.$store);
    },
    autoscalers() {
      return listAutoscalers(this.$store);
    },
  },
  created() {
    this.addonsStore.ensure();
    ensureContainerSizes(this.$store, this.app.region);
    ensureContainers(this.$store);
    ensureAutoscalers(this.$store);
    ensureCronTasks(this.$store);
  },
  methods: {
    scaleContainers(fullFormation) {
      const handler = new ScaleContainersHandler(this, this.app, fullFormation);

      handler.initComponent(this);
      handler.on("submit", () => (this.justRestarted = true));
      handler.on("finally", () => (this.justRestarted = false));
      handler.submit();
    },
    scaleContainer(formation) {
      this.scaleContainers([formation]);
    },
    stopContainer({ name }) {
      this.scaleContainer({ name, amount: 0 });
    },
    stopAll() {
      const newFormation = this.containers.items.map((container) => {
        return { name: container.name, amount: 0 };
      });

      this.scaleContainers(newFormation);
    },
    restartAll() {
      this.restartContainer({ name: undefined });
    },
    restartContainer({ name }) {
      const handler = new RestartContainersHandler(this, name);

      handler.on("submit", () => (this.justRestarted = true));
      handler.on("finally", () => (this.justRestarted = false));
      handler.initComponent(this);
      handler.submit();
    },
    autoscalerMetricChanged(e) {
      const handler = new GetAutoscalerRecommendationHandler(this, this.app);

      handler.submit(e);
      handler.on("success", (val) => (this.autoscalerRecommendedValue = val));
      handler.on("failure", () => (this.autoscalerRecommendedValue = null));
    },
    createAutoscaler(e) {
      this.autoscalerHandler = new CreateAutoscalerHandler(
        this,
        this.app,
        e.container,
      );
    },
    editAutoscaler(e) {
      this.autoscalerHandler = new EditAutoscalerHandler(
        this,
        this.app,
        e.autoscaler,
      );
    },
    cancelAutoscaler() {
      this.autoscalerHandler = null;
    },
    enableAutoscaler(e) {
      this.autoscalerActions[e.autoscaler.id] = new EnableAutoscalerHandler(
        this,
        e.autoscaler,
      );

      this.autoscalerActions[e.autoscaler.id].submit();
    },
    disableAutoscaler(e) {
      this.autoscalerActions[e.autoscaler.id] = new DisableAutoscalerHandler(
        this,
        e.autoscaler,
      );

      this.autoscalerActions[e.autoscaler.id].submit();
    },
    destroyAutoscaler(e) {
      this.autoscalerActions[e.autoscaler.id] = new DeleteAutoscalerHandler(
        this,
        e.autoscaler,
      );

      this.autoscalerActions[e.autoscaler.id].submit();
    },
    startAddonDeletion({ addon }) {
      this.deleteAddonCtx = { info: voidPromiseInfo(), app: this.app, addon };
    },
    cancelAddonDeletion() {
      this.deleteAddonCtx = null;
    },
    async confirmAddonDeletion() {
      const { addon } = this.deleteAddonCtx;

      this.deleteAddonCtx.info = this.addonsStore.destroy(addon);

      try {
        await this.deleteAddonCtx.info.promise;
        await nextTick();

        this.toastsStore.addOne({
          type: "success",
          title: this.$t("notifications.addons.deleted.success.title"),
          message: this.$t("notifications.addons.deleted.success.message"),
        });

        closeCurrentModal();
      } catch (e) {
        this.toastsStore.addOne({
          type: "error",
          title: this.$t("notifications.addons.deleted.error.title"),
          message: this.$t("notifications.addons.deleted.error.message"),
        });
      }
    },
    async resumeAddon(addon) {
      try {
        this.busyAddons[addon.id] = true;

        await this.addonsStore.resume(addon).promise;

        this.toastsStore.addOne({
          type: "success",
          title: this.$t("addons.resume.success.title"),
          message: this.$t("addons.resume.success.message"),
        });
      } catch (e) {
        this.toastsStore.addOne({
          type: "error",
          title: this.$t("addons.resume.error.title"),
          message: this.$t("addons.resume.error.message", {
            message: e.data.message,
          }),
        });
      } finally {
        this.busyAddons[addon.id] = false;
      }
    },
  },
});
</script>
