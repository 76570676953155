<template>
  <ViewComponent
    :db="db"
    :app="app"
    :forceTLSInfo="forceTLSInfo"
    :internetAccessInfo="internetAccessInfo"
    @downloadCaCertificate="downloadCaCertificate"
    @disableForceTLS="disableForceTLS"
    @enableForceTLS="enableForceTLS"
    @disableInternetAccess="disableInternetAccess"
    @enableInternetAccess="enableInternetAccess"
  ></ViewComponent>
</template>

<script>
import { ref, computed, defineComponent } from "vue";

import ViewComponent from "@/components/views/db/settings/InternetAccess.vue";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { useCurrentAppStore } from "@/stores/current/app";
import {
  useCurrentDBStore,
  currentDB,
  currentDBClient,
} from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "InternetAccess",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const forceTLSInfo = ref(voidPromiseInfo());
    const internetAccessInfo = ref(voidPromiseInfo());

    async function downloadCaCertificate() {
      const client = await currentDBClient();
      return client.Database.downloadCaCertificate();
    }

    async function enableForceTLS() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(db.id, "force-ssl").then(
        currentDbStore.refreshDBFeatures,
      );

      forceTLSInfo.value = promiseInfo(promise);

      try {
        await forceTLSInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("forceTlsEnabled.success.title"),
            message: t("forceTlsEnabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("forceTlsEnabled.error.title"),
          message: t("forceTlsEnabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function disableForceTLS() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(db.id, "force-ssl").then(
        currentDbStore.refreshDBFeatures,
      );

      forceTLSInfo.value = promiseInfo(promise);

      try {
        await forceTLSInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("forceTlsDisabled.success.title"),
            message: t("forceTlsDisabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("forceTlsDisabled.error.title"),
          message: t("forceTlsDisabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function enableInternetAccess() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(
        db.id,
        "publicly-available",
      ).then(currentDbStore.refreshDBFeatures);

      internetAccessInfo.value = promiseInfo(promise);

      try {
        await internetAccessInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("internetAccessEnabled.success.title"),
            message: t("internetAccessEnabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("internetAccessEnabled.error.title"),
          message: t("internetAccessEnabled.error.message", {
            err: e.data.error,
          }),
        });
      }
    }

    async function disableInternetAccess() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(
        db.id,
        "publicly-available",
      ).then(currentDbStore.refreshDBFeatures);

      internetAccessInfo.value = promiseInfo(promise);

      try {
        await internetAccessInfo.value.promise.then(() => {
          toastsStore.addOne({
            type: "success",
            title: t("internetAccessDisabled.success.title"),
            message: t("internetAccessDisabled.success.message"),
          });
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("internetAccessDisabled.error.title"),
          message: t("internetAccessDisabled.error.message", {
            err: e.data.error,
          }),
        });
      }
    }

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
      enableForceTLS,
      disableForceTLS,
      enableInternetAccess,
      disableInternetAccess,
      forceTLSInfo,
      internetAccessInfo,
      downloadCaCertificate,
    };
  },
});
</script>

<i18n>
en:
  forceTlsEnabled:
    success:
      title: "Force TLS being activated"
      message: "Force TLS option is in process of activation."
    error:
      title: "Force TLS activation failure"
      message: "The activation of Force TLS option failed: {err}"
  forceTlsDisabled:
    success:
      title: "Force TLS being deactivated"
      message: "Force TLS option is in process of deactivation."
    error:
      title: "Force TLS deactivate failure"
      message: "The deactivate of Force TLS option failed: {err}"
  internetAccessEnabled:
    success:
      title: "Internet Access being activated"
      message: "Internet Access option is in process of activation."
    error:
      title: "Internet Access activation failure"
      message: "The activation of Internet Access option failed: {err}"
  internetAccessDisabled:
    success:
      title: "Internet Access being deactivated"
      message: "Internet Access option is in process of deactivation."
    error:
      title: "Internet Access deactivate failure"
      message: "The deactivate of Internet Access option failed: {err}"
fr:
  forceTlsEnabled:
    success:
      title: "Force TLS en cours d'activation"
      message: "L'option Force TLS est en cours d'activation."
    error:
      title: "Échec de l'activation de l'option Force TLS"
      message: "L'activation de l'option Force TLS a échoué: {err}"
  forceTlsDisabled:
    success:
      title: "Force TLS en cours de désactivation"
      message: "L'option Force TLS est en cours de désactivation."
    error:
      title: "Échec de a désactivation de Force TLS"
      message: "La désactivation de l'option Force TLS a échoué: {err}"
  internetAccessEnabled:
    success:
      title: "Internet Access en cours d'activation"
      message: "L'option Internet Access est en cours d'activation."
    error:
      title: "Échec de l'activation de l'option Internet Access"
      message: "L'activation de l'option Internet Access a échoué: {err}"
  internetAccessDisabled:
    success:
      title: "Internet Access en cours de désactivation"
      message: "L'option Internet Access est en cours de désactivation."
    error:
      title: "Échec de a désactivation de Internet Access"
      message: "La désactivation de l'option Internet Access a échoué: {err}"
</i18n>
