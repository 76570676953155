<template>
  <DbBannersManager
    v-if="db && dbMaintenance"
    :app="app"
    :db="db"
    :dbMaintenance="dbMaintenance"
    :notifiedItems="notifiedItems"
    :ongoingItems="ongoingItems"
  />
  <router-view v-if="db" :app="app" :db="db" />
</template>

<script>
import { defineComponent } from "vue";

import DbBannersManager from "@/components/parts/db/banners/DbBannersManager.vue";

export default defineComponent({
  name: "DbRoot",
  components: {
    DbBannersManager,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
    dbMaintenance: {
      type: Object,
      required: false,
    },
    notifiedItems: {
      type: Object,
      required: false,
    },
    ongoingItems: {
      type: Object,
      required: false,
    },
  },
  computed: {
    accessAsAdmin() {
      // TODO
      return false;
    },
  },
});
</script>
