<template>
  <div class="rounded border-2 px-2 py-1 flex text-scale-6" :style="widthStyle">
    <div class="truncate">
      {{ query }}
    </div>
    <CopyTableButton
      class="cursor-pointer ml-auto"
      @click="copyToClipboard(query)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CopyTableButton from "@/components/organisms/buttons/CopyTableButton.vue";
import { copyToClipboard } from "@/lib/clipboard";

export default defineComponent({
  name: "QueryContainer",
  components: {
    CopyTableButton,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    widthStyle: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyToClipboard,
  },
});
</script>
