<template>
  <FormModal
    :withCancelBtn="false"
    :promiseInfo="context.info"
    :title="$t('title')"
    :fieldErrors="errors"
    panelClasses="sm:w-full sm:max-w-lg"
    :submitLabel="$t('action')"
    @close="(e) => $emit('close', e)"
    @submit="onSubmit"
  >
    <div class="mt-6">
      <Field
        v-slot="{ field, errors, handleChange }"
        v-model="form.name"
        name="name"
        rules="required|min:6|max:32|alpha_dash|databaseUsername"
      >
        <span
          class="text-sm font-medium leading-5 h-6 text-scale-10 capitalize"
        >
          {{ $t("username") }}
        </span>
        <TextInput
          :modelValue="field.value"
          :name="field.name"
          :errors="errors"
          :label="$t('username')"
          @update:modelValue="handleChange"
        />
        <ul class="mt-4 list-inside list-disc text-scale-10">
          {{
            $t("usernameValidations.title")
          }}
          <li>{{ $t("usernameValidations.text1") }}</li>
          <li>{{ $t("usernameValidations.text2") }}</li>
          <li>{{ $t("usernameValidations.text3") }}</li>
        </ul>
      </Field>
    </div>
    <div class="text-scale-10 mt-4">
      <span
        class="text-sm font-medium leading-5 h-6 text-scale-10 capitalize"
        >{{ $t("permissions") }}</span
      >
      <Field
        v-slot="{ field, errors, handleChange }"
        v-model="readOnly"
        name="readOnly"
      >
        <span class="items-center">
          <div class="flex flex-row mb-5">
            <div class="flex items-center mr-2">
              <input
                type="checkbox"
                disabled="true"
                class="bg-scale-0 border-2 border-scale-3 form-checkbox box-border focus:shadow-none h-4 w-4 transition duration-150 focus:border-scale-4 ease-in-out"
                :class="'bg-scale-2 cursor-not-allowed'"
                checked
              />
              <label :for="'input-' + inputId" class="ml-3 block text-scale-4">
                <slot name="label">{{ $t("read") }}</slot>
              </label>
            </div>

            <CheckboxInput
              :name="field.name"
              :errors="errors"
              :label="$t('write')"
              @update:modelValue="handleChange"
            />
          </div>
        </span>
      </Field>
    </div>

    <SCAlert v-if="context.info?.isError" class="my-4" kind="error">
      <p class="px-4 font-bold">{{ context.error }}</p>
    </SCAlert>

    <InformationAlert :marginSize="3">
      <span>
        {{ $t("help") }}
      </span>
    </InformationAlert>
  </FormModal>
</template>

<script>
import { Field } from "vee-validate";
import { computed, defineComponent, reactive } from "vue";

import TextInput from "@/components/atoms/inputs/TextInput.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import CheckboxInput from "@/components/molecules/inputs/CheckboxInput.vue";
import FormModal from "@/components/molecules/modals/FormModal.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export function initForm(extra) {
  return {
    ...extra,
  };
}

export default defineComponent({
  components: {
    Field,
    FormModal,
    TextInput,
    CheckboxInput,
    SCAlert,
    InformationAlert,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const form = reactive(initForm({ read_only: true }));

    const readOnly = computed({
      get() {
        return !form.read_only;
      },
      set(newValue) {
        form.read_only = !newValue;
      },
    });

    const formattedErrors = computed(() => {
      if (!props.context?.info?.err) return null;

      return props.context.info.err.data.db;
    });

    const onSubmit = () => {
      emit("submit", form);
    };

    const errors = computed(() => props.context.errors);

    return {
      form,
      errors,
      formattedErrors,
      onSubmit,
      readOnly,
    };
  },
});
</script>

<i18n>
en:
  title: "Add a new user"
  usernameValidations:
    title: "The username must:"
    text1: "Start with a letter"
    text2: "Be between 6 and 32 characters"
    text3: "Contain only alphanumeric characters and underscores (_)"
  username: "Username"
  permissions: "Permissions"
  read: "Read (default)"
  write: "Write (optional)"
  action: "Add this user"
  help: "A secure password will be generated automatically."
fr:
  title: "Ajouter un nouvel utilisateur"
  usernameValidations:
    title: "Le nom d'utilisateur doit :"
    text1: "Commencer par une lettre"
    text2: "Contenir entre 6 et 32 caractères"
    text3: "Contenir que des caractères alphanumériques et des tiret bas"
  username: "Nom d'utilisateur"
  permissions: "Permissions"
  read: "Lecture (par défaut)"
  write: "Écriture (optionnel)"
  action: "Ajouter cet utilisateur"
  help: "Un mot de passe sécurisé sera généré automatiquement."
</i18n>
