<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')" />
    </template>

    <template #body>
      <div class="mt-4">
        <div class="text-scale-10 font-normal text-base">
          {{ $t("currentVersion") }}
        </div>
        <div
          v-if="dbVersion.promiseInfo.isSuccess"
          class="text-scale-10 font-medium text-base flex flex-row"
        >
          <div class="humanize">
            {{ databaseName(dbName) }}
          </div>
          <div class="ml-1">{{ readableVersion(currentDbVersion) }}</div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { databaseName } from "@/lib/utils/databaseName";

export default defineComponent({
  name: "DbVersionCard",
  components: { Card, CardHeader },

  props: {
    dbName: String,
    dbVersion: Object,
  },

  computed: {
    currentDbVersion() {
      return this.dbVersion?.item;
    },
  },

  methods: {
    databaseName,
    readableVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch}`;
    },
    readableFullVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch} v${dbVersion.build}`;
    },
  },
});
</script>

<i18n>
en:
  title: "Database Version"
  subtitle: "Details of the upgrades available for your addon"
  currentVersion: "Current version"
fr:
  title: "Version de la base de données"
  subtitle: "Détail des mises à jour disponibles pour votre addon"
  currentVersion: "Version actuelle"
</i18n>
