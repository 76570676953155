import { FormHandler } from "@/lib/handlers/form";
import { Contact as HDSContact, CreateParams } from "@/lib/scalingo/contacts";
import { Nullable } from "@/lib/utils/types";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";

export class CreateHDSContactHandler extends FormHandler<HDSContact> {
  keyPath = "hdsContact.create";

  data(): Nullable<CreateParams> {
    return {
      kind: null,
      entity: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(event: CreateParams): Promise<void> {
    const store = usePointOfContactStore();

    this.followPinia(store.create(event));
  }
}
