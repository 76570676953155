export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current plan"])},
        "updateAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update available: ", _interpolate(_named("version"))])},
        "buildAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Build update available: ", _interpolate(_named("from")), " → ", _interpolate(_named("to"))])},
        "upgradePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade this plan"])},
        "status": {
          "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating"])},
          "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])},
          "migrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrating"])},
          "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating"])},
          "upgrading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrading"])},
          "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopped"])}
        }
      },
      "fr": {
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan courant"])},
        "updateAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour disponible: ", _interpolate(_named("version")), " "])},
        "buildAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour build disponible: ", _interpolate(_named("from")), " → ", _interpolate(_named("to"))])},
        "upgradePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à niveau ce plan"])},
        "status": {
          "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
          "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnelle"])},
          "migrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration"])},
          "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
          "upgrading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à niveau"])},
          "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêtée"])}
        }
      }
    }
  })
}
