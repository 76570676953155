import { defineStore } from "pinia";
import {
  CreateParams,
  UpdateParams,
} from "scalingo/lib/params/regional/addons";

import i18n from "@/i18n";
import { useModelsStore, toPublicStore } from "@/lib/pinia/use-models-store";
import { setupPolling } from "@/lib/pinia/utils/polling";
import {
  Addon,
  modifyCollection,
  modifyItem,
} from "@/lib/scalingo/modifiers/addons";
import { currentApp } from "@/stores/current/app";
import { useSessionStore } from "@/stores/session";
import { useToastsStore } from "@/stores/toasts";

export const useAppAddonsStore = defineStore("app/addons", () => {
  const { client } = useSessionStore();

  const store = useModelsStore({
    fetchPromise: async () => {
      const app = currentApp();

      const response = await client(app.region).Addons.for(app.id);
      const addons = modifyCollection(response, app);

      return addons.map(setupAddonPolling);
    },
  });

  /**
   * Re-fetch an addon as long as it is provisioning.
   * Once done, display a notification
   */
  function setupAddonPolling(addon: Addon): Addon {
    const stopFn = () => addon.status !== "provisioning";
    const refreshFn = () => fetchById(addon.id);

    const teardownFn = () => {
      const toastsStore = useToastsStore();

      toastsStore.addOne({
        title: i18n.global
          .t("notifications.addons.provisioned.title")
          .toString(),
        message: i18n.global
          .t("notifications.addons.provisioned.message", {
            addon: addon.addon_provider.name,
          })
          .toString(),
      });
    };

    setupPolling({
      id: addon.id,
      kind: "addon",
      stopFn,
      refreshFn,
      teardownFn,
    });

    return addon;
  }

  function fetchById(id: string) {
    const app = currentApp();

    return store.insertItem(
      client(app.region)
        .Addons.getAddon(app.id, id)
        .then((addon) => modifyItem(addon, app))
        .then(setupAddonPolling),
    );
  }

  function create(payload: CreateParams) {
    const app = currentApp();

    return store.insertItem(
      client(app.region)
        .Addons.create(app.id, payload)
        .then((addon) => modifyItem(addon, app))
        .then(setupAddonPolling),
    );
  }

  function update(addon: Addon, payload: UpdateParams) {
    const app = currentApp();

    return store.insertItem(
      client(app.region)
        .Addons.update(app.id, addon.id, payload)
        //@ts-expect-error incomplete typings
        .then((addon) => modifyItem(addon, app))
        .then(setupAddonPolling),
    );
  }

  function destroy(addon: Addon) {
    const app = currentApp();

    return store.removeItem(
      addon,
      client(app.region).Addons.destroy(app.id, addon.id),
    );
  }

  function resume(addon: Addon) {
    const app = currentApp();

    return store.insertItem(
      client(app.region)
        .Addons.resume(app.id, addon.id)
        .then((r) => modifyItem(r.addon, app)),
    );
  }

  return toPublicStore(store, {
    fetchById,
    create,
    update,
    destroy,
    resume,
  });
});
