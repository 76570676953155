import { AccountRoutes } from "@/router/account/names";
import { AppDbRoutes } from "@/router/app/db/names";
import { AppRoutes } from "@/router/app/names";
import { BillingRoutes } from "@/router/billing/names";

export const Routes = {
  Welcome: "Welcome",
  Overview: "Overview",
  Projects: "Projects",
  Consumption: "Consumption",
  CollaborationsAccept: "CollaborationAccept",
  NotFoundError: "NotFoundError",
  About: "About",
  Account: AccountRoutes,
  Billing: BillingRoutes,
  Create: {
    App: "CreateApp",
  },
  Configure: {
    App: "ConfigureApp",
  },
  App: AppRoutes,
  Db: AppDbRoutes,
};
