import { defineStore } from "pinia";

import {
  useResourceStore,
  toPublicStore,
} from "@/lib/pinia/use-resource-store";
import { CreateParams, UpdateParams } from "@/lib/scalingo/contacts";
import { useSessionStore } from "@/stores/session";

export const usePointOfContactStore = defineStore("pointOfContact", () => {
  const { privateClient } = useSessionStore();

  const store = useResourceStore({
    fetchPromise: () => privateClient().Contacts.show(),
  });

  const { updateData, destroyData } = store;

  return toPublicStore(store, {
    create(payload: CreateParams) {
      return updateData(privateClient().Contacts.create(payload));
    },
    update(payload: UpdateParams) {
      return updateData(privateClient().Contacts.update(payload));
    },
    destroy() {
      return destroyData(privateClient().Contacts.destroy());
    },
  });
});
