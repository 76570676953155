import { loadStripe } from "@stripe/stripe-js";
import { APIError } from "scalingo/lib/errors";

import { SetupIntent as ScalingoSetupIntent } from "@/lib/scalingo/setup-intents";

import type {
  Stripe,
  StripeCardElement,
  Source,
  CreateSourceData,
  StripeIbanElement,
  SetupIntent as StripeSetupIntent,
  StripeConstructorOptions,
} from "@stripe/stripe-js";

const STRIPE_PUBLISHABLE_KEY = process.env
  .VUE_APP_STRIPE_PUBLISHABLE_KEY as string;

let _stripeClient: Stripe | null = null;

export async function stripeClient() {
  if (!_stripeClient) {
    const opts: StripeConstructorOptions = {};

    if (process.env.VUE_APP_PLATFORM_ENV !== "production") {
      opts.apiVersion = "2020-03-02";
    }

    _stripeClient = await loadStripe(STRIPE_PUBLISHABLE_KEY, opts);
  }

  return _stripeClient;
}

export type StripeSourceCreateParams = {
  iban: string;
  holder: string;
  email: string;
};

export type StripeSEPACreateParams = {
  holder: string;
  email: string;
};

export async function createStripeSource(
  client: Stripe,
  payload: StripeSourceCreateParams,
): Promise<Source> {
  const stripeOpts: CreateSourceData = {
    type: "sepa_debit",
    sepa_debit: {
      iban: payload.iban.replace(/ /g, ""),
    },
    owner: {
      name: payload.holder,
      email: payload.email,
    },
    mandate: {
      notification_method: "email",
    },
    currency: "eur",
  };

  return new Promise<Source>((resolve, reject) => {
    client.createSource(stripeOpts).then(
      (response) => {
        const { source, error } = response;

        if (source) {
          resolve(source);
        } else if (error) {
          // Should not happen as this is validated client side.
          if (error.code == "invalid_bank_account_iban" && error.message) {
            // Simulating Scalingo API error to bind to the right field.
            reject(new APIError(400, { errors: { iban: [error.message] } }));
            return;
          }

          // Should not happen as this is validated client side.
          // The condition was guessed from attempts.
          if (
            (error.code == "invalid_owner_name" ||
              error.param == "owner[name]") &&
            error.message
          ) {
            // Simulating Scalingo API error to bind to the right field.
            reject(new APIError(400, { errors: { holder: [error.message] } }));
            return;
          }

          // Otherwise, raise the error as is.
          reject({ stripe: error });
        } else {
          reject({ stripe: { code: "generic_error" } });
        }
      },
      () => {
        reject({ stripe: { code: "generic_error" } });
      },
    );
  });
}

export function setupStripeSepa(
  client: Stripe,
  setupIntent: ScalingoSetupIntent,
  el: StripeIbanElement,
  payload: StripeSEPACreateParams,
): Promise<StripeSetupIntent> {
  const promise = client.confirmSepaDebitSetup(setupIntent.client_secret, {
    payment_method: {
      sepa_debit: el,
      billing_details: { name: payload.holder, email: payload.email },
    },
  });

  return new Promise((resolve, reject) => {
    promise.then(
      (response) => {
        const { setupIntent, error } = response;

        // stripeResponse will have either error or setupIntent, never both according to doc.
        if (setupIntent) {
          resolve(setupIntent);
        } else if (error) {
          reject({ stripe: error });
        } else {
          reject({ stripe: { code: "generic_error" } });
        }
      },
      () => {
        reject({ stripe: { code: "generic_error" } });
      },
    );
  });
}

export function setupStripeCard(
  client: Stripe,
  setupIntent: ScalingoSetupIntent,
  el: StripeCardElement,
  holder: string,
): Promise<StripeSetupIntent> {
  const promise = client.confirmCardSetup(setupIntent.client_secret, {
    payment_method: {
      card: el,
      billing_details: { name: holder },
    },
  });

  return new Promise((resolve, reject) => {
    promise.then(
      (response) => {
        const { setupIntent, error } = response;

        // stripeResponse will have either error or setupIntent, never both according to doc.
        if (setupIntent) {
          resolve(setupIntent);
        } else if (error) {
          reject({ stripe: error });
        } else {
          reject({ stripe: { code: "generic_error" } });
        }
      },
      () => {
        reject({ stripe: { code: "generic_error" } });
      },
    );
  });
}
