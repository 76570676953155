import { unpackData } from "scalingo/lib/utils";

import ScalingoDBApi from "@/lib/scalingo/dbapi/client";

export type BackupStatus = "scheduled" | "running" | "done" | "error";

export interface Backup {
  id: string;
  created_at: string;
  started_at: string | null;
  name: string;
  method: string;
  size: number;
  status: BackupStatus;
  database_id: string;
}

export interface BackupRestoration {
  id: string;
  created_at: string;
  started_at: string | null;
  ended_at: string | null;
  method:
    | "backup" // Restoration comes from a backup made on the same region (on the same db or a different one)
    | "remote"; // Restoration comes from a remote URL (used for region migration);
  status: BackupStatus;
  database_id: string;
  source_backup_id: string;
}

/**
 * Backups API Client
 */
export default class Backups {
  /** Scalingo DB API Client */
  _client: ScalingoDBApi;

  /**
   * Create a new "thematic" client
   * @param client Scalingo DB API Client
   */
  constructor(client: ScalingoDBApi) {
    this._client = client;
  }

  /**
   * Retrieve a specific backup.
   * This endpoint doesn't require the DB id
   * @param backupId ID of the backup
   */
  find(id: string): Promise<Backup> {
    return unpackData(
      this._client.apiClient().get(`/backups/${id}`),
      "database_backup",
    );
  }

  /**
   * Get list of backups of a database
   * @see https://developers.scalingo.com/databases/backups#list-database-backups
   * @param addonId ID of the addon/database
   */
  for(addonId: string): Promise<Backup[]> {
    return unpackData(
      this._client.apiClient().get(`/databases/${addonId}/backups`),
      "database_backups",
    );
  }

  /**
   * Create a (manual) backup for a database. The backup is returned as "sheduled" and
   * will be performed asynchronously
   * @see https://developers.scalingo.com/databases/backups#create-a-new-backup
   * @param addonId ID of the addon/database
   */
  create(addonId: string): Promise<Backup> {
    return unpackData(
      this._client.apiClient().post(`/databases/${addonId}/backups`),
      "database_backup",
    );
  }

  /**
   * Get a pre-signed URL to download the backup
   * @see https://developers.scalingo.com/databases/backups#get-a-backup-download-link
   * @param dbId ID of the addon/database
   * @param id ID of the back
   */
  archiveUrl(dbId: string, id: string): Promise<string> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}/backups/${id}/archive`),
      "download_url",
    );
  }

  /**
   * Restore a backup
   * @param dbId ID of the addon/database
   * @param id ID of the backup
   */
  restore(dbId: string, id: string): Promise<BackupRestoration> {
    return unpackData(
      this._client.apiClient().post(`/databases/${dbId}/backups/${id}/restore`),
      "backup_restoration",
    );
  }
}
