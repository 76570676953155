<template>
  <PostgresExtensions
    v-if="db.type_name === 'postgresql'"
    :db="db"
    :extensionList="extensionList"
    class="mb-6"
  />
  <RedisPersistence
    v-if="db.type_name === 'redis'"
    :db="db"
    :cacheInfo="cacheInfo"
    :RDBInfo="RDBInfo"
    :AOFInfo="AOFInfo"
    :enableCache="enableCache"
    :disableCache="disableCache"
    :enableAOF="enableAOF"
    :disableAOF="disableAOF"
    :enableRDB="enableRDB"
    :disableRDB="disableRDB"
    class="mb-6"
  />
  <ClusterTopology :db="db" />
</template>

<script>
import { defineComponent } from "vue";

import ClusterTopology from "@/components/parts/dbSettings/configuration/ClusterTopology.vue";
import PostgresExtensions from "@/components/parts/dbSettings/configuration/PostgresExtensions.vue";
import RedisPersistence from "@/components/parts/dbSettings/configuration/RedisPersistence.vue";

export default defineComponent({
  name: "Configuration",
  components: { ClusterTopology, PostgresExtensions, RedisPersistence },
  props: {
    db: Object,
    extensionList: Array,
    cacheInfo: Object,
    RDBInfo: Object,
    AOFInfo: Object,
    enableCache: Function,
    disableCache: Function,
    enableAOF: Function,
    disableAOF: Function,
    enableRDB: Function,
    disableRDB: Function,
  },
});
</script>
