export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update the database"])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation will update the database"])},
          "line2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["from version ", _interpolate(_named("currentVersion")), " to ", _interpolate(_named("nextVersion"))])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à niveau la base de données"])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération va mettre à niveau la base de données"])},
          "line2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["de la version ", _interpolate(_named("currentVersion")), " à ", _interpolate(_named("nextVersion"))])}
        }
      }
    }
  })
}
