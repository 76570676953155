import { defineStore } from "pinia";

import {
  useCollectionStore,
  toPublicStore,
} from "@/lib/pinia/use-collection-store";
import { useSessionStore } from "@/stores/session";
import { currentUser } from "@/stores/user";

export const useOrdersStore = defineStore("orders", () => {
  const { privateClient } = useSessionStore();
  const store = useCollectionStore({
    fetchPromise: async () => {
      const user = currentUser();

      const response = await privateClient().Orders.all({ owner_id: user.id });
      return response.orders;
    },
  });

  return toPublicStore(store, {});
});
