<template>
  <ConfirmModal
    :immediate="false"
    :promiseInfo="context.info"
    :strongConfirmation="context.db.app_name"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p>
      {{ $t("disclaimer.line1") }}

      <span class="font-mono text-sm underline">
        {{ context.db.name }}
      </span>

      {{
        $t("disclaimer.line2", {
          currentVersion: readableVersion(currentDbVersion),
          nextVersion: readableVersion(nextDbVersion),
        })
      }}
    </p>

    <p class="mt-2"></p>
  </ConfirmModal>
</template>

<script>
import { computed, defineComponent } from "vue";

import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  components: { ConfirmModal },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
  setup(props) {
    function readableVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch}`;
    }
    return {
      currentDbVersion: computed(() => props.context.dbVersion?.item),
      nextDbVersion: computed(
        () => props.context.dbVersion?.item?.next_upgrade,
      ),
      readableVersion,
    };
  },
});
</script>

<i18n>
en:
  title: "Update the database"
  disclaimer:
    line1: "This operation will update the database"
    line2: "from version {currentVersion} to {nextVersion}"
fr:
  title: "Mettre à niveau la base de données"
  disclaimer:
    line1: "Cette opération va mettre à niveau la base de données"
    line2: "de la version {currentVersion} à {nextVersion}"
</i18n>
