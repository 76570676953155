export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Plan"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of your current plan"])},
        "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change plan"])},
        "scalingoFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo for "])},
        "redundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy"])},
        "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single-node (98% SLA)"])},
        "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multi-node (99,96% SLA)"])},
        "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory"])},
        "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
        "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
        "higherPlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change to a Business plan and enjoy increased redundancy and a 99.96% SLA."])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more."])},
        "retention": {
          "oneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year retention"])},
          "oneYearWithPITR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year retention and 7 days PITR"])},
          "oneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month retention"])},
          "oneMonthWithPITR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month retention and 7 days PITR"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["none"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de la base de données"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de votre plan actuel"])},
        "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de plan"])},
        "scalingoFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo avec "])},
        "redundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redondance"])},
        "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mono-noeud (SLA 98%)"])},
        "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multi-noeuds (SLA 99,96%)"])},
        "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire"])},
        "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockage"])},
        "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
        "higherPlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez pour un plan Business et profitez d’une redondance accrue ainsi que d’un SLA de 99,96 %."])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus."])},
        "retention": {
          "oneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an de rétention"])},
          "oneYearWithPITR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an de rétention et 7 jours de PITR"])},
          "oneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois de rétention"])},
          "oneMonthWithPITR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois de rétention et 7 jours de PITR"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aucun"])}
        }
      }
    }
  })
}
