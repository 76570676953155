import { defineStore } from "pinia";
import { useRoute } from "vue-router";

import {
  useResourceStore,
  toPublicStore,
} from "@/lib/pinia/use-resource-store";
import { useSessionStore } from "@/stores/session";

export const useOrderStore = defineStore("order", () => {
  const { privateClient } = useSessionStore();
  const store = useResourceStore({
    fetchPromise: async () => {
      const route = useRoute();

      const response = await privateClient().Orders.show(
        route.params.id as string,
      );
      return response;
    },
  });

  return toPublicStore(store, {});
});
