import { defineStore } from "pinia";

import i18n from "@/i18n";
import { useModelsStore, toPublicStore } from "@/lib/pinia/use-models-store";
import { setupPolling } from "@/lib/pinia/utils/polling";
import {
  Backup,
  modifyCollection,
  modifyItem,
} from "@/lib/scalingo/modifiers/db/backups";
import { currentAddon, currentDBClient } from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

const backupIntervalIDs: Record<string, NodeJS.Timeout> = {};

export const useDbBackupsStore = defineStore("dbBackups", () => {
  const store = useModelsStore({
    fetchPromise: async () => {
      const addon = await currentAddon();
      const client = await currentDBClient();

      return client.Backups.for(addon.id)
        .then(modifyCollection)
        .then((c) => c.map(setupBackupPolling));
    },
  });

  // Trigger a manual backup. Returns quickly, the actual backup is in the background.
  async function scheduleManual() {
    const addon = currentAddon();
    const client = await currentDBClient();

    const promise = client.Backups.create(addon.id)
      .then(modifyItem)
      .then(setupBackupPolling);

    return store.insertItem(promise);
  }

  // Fetch one backup object
  async function fetchBackup(id: string) {
    const client = await currentDBClient();
    const promise = client.Backups.find(id)
      .then(modifyItem)
      .then(setupBackupPolling);

    return store.insertItem(promise);
  }

  function setupBackupPolling(backup: Backup): Backup {
    const stopFn = () => backup.status === "done" || backup.status === "error";
    const refreshFn = () => fetchBackup(backup.id);

    const teardownFn = () => {
      const toastsStore = useToastsStore();

      toastsStore.addOne({
        title: i18n.global
          .t("notifications.databases.backups.manual.title")
          .toString(),
        message: i18n.global
          .t("notifications.databases.backups.manual.message")
          .toString(),
      });
    };

    setupPolling({
      id: backup.id,
      kind: "backup",
      stopFn,
      refreshFn,
      teardownFn,
    });

    return backup;
  }

  // Clears the store + all the polling functions
  function clearData() {
    store.clearData();

    // Clear all the watchers stored
    Object.keys(backupIntervalIDs).forEach((key) => {
      clearInterval(backupIntervalIDs[key]);
      delete backupIntervalIDs[key];
    });
  }

  return toPublicStore(store, {
    scheduleManual,
    fetchBackup,
    setupBackupPolling,
    clearData,
  });
});
