export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "forceTlsEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS being activated"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS option is in process of activation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS activation failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activation of Force TLS option failed: ", _interpolate(_named("err"))])}
          }
        },
        "forceTlsDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS being deactivated"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS option is in process of deactivation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS deactivate failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The deactivate of Force TLS option failed: ", _interpolate(_named("err"))])}
          }
        },
        "internetAccessEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access being activated"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access option is in process of activation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access activation failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activation of Internet Access option failed: ", _interpolate(_named("err"))])}
          }
        },
        "internetAccessDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access being deactivated"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access option is in process of deactivation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access deactivate failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The deactivate of Internet Access option failed: ", _interpolate(_named("err"))])}
          }
        }
      },
      "fr": {
        "forceTlsEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS en cours d'activation"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option Force TLS est en cours d'activation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'activation de l'option Force TLS"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'activation de l'option Force TLS a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "forceTlsDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS en cours de désactivation"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option Force TLS est en cours de désactivation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de a désactivation de Force TLS"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La désactivation de l'option Force TLS a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "internetAccessEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access en cours d'activation"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option Internet Access est en cours d'activation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'activation de l'option Internet Access"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'activation de l'option Internet Access a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "internetAccessDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access en cours de désactivation"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option Internet Access est en cours de désactivation."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de a désactivation de Internet Access"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La désactivation de l'option Internet Access a échoué: ", _interpolate(_named("err"))])}
          }
        }
      }
    }
  })
}
