<template>
  <ViewComponent
    :app="app"
    :addon="addon"
    :db="db"
    :dbVersion="dbVersion"
    :dbPlan="dbPlan"
    :deleteAddonCtx="deleteAddonCtx"
    @cancelAddonDeletion="cancelAddonDeletion"
    @confirmAddonDeletion="confirmAddonDeletion"
    @startAddonDeletion="startAddonDeletion"
  ></ViewComponent>
</template>

<script>
import { ref, computed, defineComponent, onBeforeMount, nextTick } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/db/settings/General.vue";
import { closeCurrentModal } from "@/lib/modals";
import { voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { Routes } from "@/router/names";
import { useAppAddonsStore } from "@/stores/app/addons";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbPlanStore } from "@/stores/db/plan";
import { useDbVersionStore } from "@/stores/db/version";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "GeneralContainer",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const dbVersionStore = useDbVersionStore();
    const dbPlanStore = useDbPlanStore();
    const addonsStore = useAppAddonsStore();
    const deleteAddonCtx = ref(null);
    const router = useRouter();

    const app = currentAppStore.appInfoOrFull;
    const addon = currentDbStore.addon;

    onBeforeMount(() => {
      dbVersionStore.ensure();
      dbPlanStore.ensure();
    });

    async function confirmAddonDeletion() {
      const addon = deleteAddonCtx.value.addon;

      deleteAddonCtx.value.info = addonsStore.destroy(addon);

      try {
        await deleteAddonCtx.value.info.promise;
        await nextTick();

        toastsStore.addOne({
          type: "success",
          title: t("notifications.addons.deleted.success.title"),
          message: t("notifications.addons.deleted.success.message"),
        });

        closeCurrentModal();
        router.push({
          name: Routes.App.Root,
          params: { region: app.region, id: app.name },
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("notifications.addons.deleted.error.title"),
          message: t("notifications.addons.deleted.error.message"),
        });
      }
    }

    return {
      db: computed(() => currentDbStore.database),

      dbVersion: computed(() => {
        return {
          item: dbVersionStore.item,
          promiseInfo: dbVersionStore.promiseInfo,
        };
      }),
      dbPlan: computed(() => {
        return {
          item: dbPlanStore.item,
          promiseInfo: dbPlanStore.promiseInfo,
        };
      }),
      startAddonDeletion() {
        deleteAddonCtx.value = {
          info: voidPromiseInfo(),
          app: app,
          addon: addon,
        };
      },
      cancelAddonDeletion() {
        deleteAddonCtx.value = null;
      },
      confirmAddonDeletion,
      deleteAddonCtx,
      app,
      addon,
    };
  },
});
</script>
