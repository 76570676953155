<template>
  <NotFoundError v-if="notFound" />
  <DbLoading v-else-if="!db" />
  <ViewComponent
    v-else-if="allowedToView"
    :app="app"
    :db="db"
    :dbMaintenance="dbMaintenance"
    :notifiedItems="notifiedItems"
    :ongoingItems="ongoingItems"
  />
  <DbNotRunning v-else :app="app" :db="db" :withCartridge="true" />
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import DbLoading from "@/components/views/db/DbLoading.vue";
import DbNotRunning from "@/components/views/db/DbNotRunning.vue";
import ViewComponent from "@/components/views/db/DbRoot.vue";
import NotFoundError from "@/components/views/misc/NotFoundError.vue";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbMaintenanceStore } from "@/stores/db/maintenance";
import { useSessionStore } from "@/stores/session";
import { currentUser } from "@/stores/user";

const DB_IN_PREVIEW = ["redis", "postgresql"];

export default defineComponent({
  name: "DbRootContainer",
  components: { DbLoading, ViewComponent, NotFoundError, DbNotRunning },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const store = currentDbStore;
    const sessionStore = useSessionStore();
    const route = useRoute();
    const router = useRouter();
    const app = currentAppStore.appInfoOrFull;
    var dbMaintenanceStore = ref({ items: [], promiseInfo: {} });
    const dbMaintenance = computed(() => {
      return {
        items: dbMaintenanceStore.value.items,
        promiseInfo: dbMaintenanceStore.value.promiseInfo,
      };
    });
    const notifiedItems = ref([]);
    const ongoingItems = ref([]);

    onBeforeMount(() => {
      if (
        userIsAdminOrHasFlag(currentUser(), "employee") ||
        DB_IN_PREVIEW.includes(route.params.dbId)
      ) {
        sessionStore.showMenu("db");
      } else {
        router.push({
          name: Routes.App.Root,
          params: { region: app.region, id: app.name },
        });
      }
    });

    onBeforeUnmount(() => {
      sessionStore.hideMenu();
    });

    const db = computed(() => currentDbStore.database);

    watch(db, async (newValue, oldValue) => {
      if (!newValue) {
        return;
      }
      if (oldValue && newValue.status === oldValue.status) {
        return;
      }
      const status = newValue.status;
      const polling = ["restarting", "updating", "upgrading"];

      if (polling.includes(status)) {
        currentDbStore.setupStatusDatabasePolling(newValue, status);
      }
    });

    watch(
      () =>
        store.addonPromiseInfo.isFinished &&
        store.databasePromiseInfo.isFinished,
      () => {
        if (
          store.addonPromiseInfo.isSuccess &&
          store.databasePromiseInfo.isSuccess
        ) {
          dbMaintenanceStore.value = useDbMaintenanceStore();
          dbMaintenanceStore.value.ensure({ staleAfter: "always" });
        }
      },
    );

    watch(
      () => dbMaintenance.value.items,
      (newItems) => {
        if (newItems) {
          notifiedItems.value = newItems.filter(
            (item) => item.status === "notified",
          );
          ongoingItems.value = newItems.filter(
            (item) => item.status === "running",
          );
        }
      },
      { immediate: true },
    );

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: db,
      dbMaintenance: dbMaintenance,
      notifiedItems: notifiedItems,
      ongoingItems: ongoingItems,
      addon: computed(() => currentDbStore.addon),
      notFound: computed(() => {
        return (
          store.addonPromiseInfo.isError || store.databasePromiseInfo.isError
        );
      }),
      route,
      allowedToView: computed(() => {
        const runningStatus = db.value.status === "running";
        const readOnlyStatuses = [
          "upgrading",
          "updating",
          "restarting",
        ].includes(db.value.status);
        const routeIsLogsOrMetrics =
          route.name === "DBLogsLive" || route.name === "DBMetrics";
        return runningStatus || (routeIsLogsOrMetrics && readOnlyStatuses);
      }),
      currentDbStore,
    };
  },
  async created() {
    this.currentDbStore.setCurrentDB(this.$route.params.dbId);
  },
  beforeUnmount() {
    this.currentDbStore.unsetCurrentDB();
  },
});
</script>
