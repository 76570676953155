import { noop } from "lodash";

import {
  CollectionStore,
  FullCollectionStore,
  InsertItemFn,
  RemoveItemFn,
  useCollectionStore,
} from "@/lib/pinia/use-collection-store";
import { DataStoreOptions } from "@/lib/pinia/use-data-store";
import { promiseInfo as promiseInfoFn } from "@/lib/promises/info";

export { observePromise } from "@/lib/promises/info";

/** A model is an object with an ID key */
export type ModelItem = {
  id: string;
};

/**
 * A model collection store.
 */
export type ModelsStore<DataType extends ModelItem> = CollectionStore<DataType>;

/**
 * The private interface of the collection store.
 * Extra items are internal helpers, used to define other stores.
 */
export type FullModelsStore<DataType extends ModelItem> =
  FullCollectionStore<DataType>;

/** Returns an data store tailored for a collection of models */
export function useModelsStore<DataType extends ModelItem>(
  options: DataStoreOptions<DataType[]>,
): FullModelsStore<DataType> {
  const store = useCollectionStore(options);

  /**
   * Finds a model by its ID and returns its index or null
   */
  const findModelIndex = function (id: string) {
    if (!store.items.value) return null;

    // findIndex returns -1 if the item is not in the collection, which is annoying
    const index = store.items.value.findIndex((elem) => elem.id === id);

    // We return null instead, it makes more sense.
    return index > -1 ? index : null;
  };

  // Inserts or updates a model in the store
  const insertItem: InsertItemFn<DataType> = function (promise) {
    const info = promiseInfoFn(promise);

    promise.then((newData) => {
      if (!store.items.value) store.items.value = [];

      const modelIndex = findModelIndex(newData.id);

      if (modelIndex === null) {
        store.items.value.push(newData);
      } else {
        store.items.value[modelIndex] = newData;
      }
    }, noop);

    return info;
  };

  // Removes a model in the store
  const removeItem: RemoveItemFn<DataType> = function (model, promise) {
    const info = promiseInfoFn(promise);

    promise.then(() => {
      if (!store.items.value) return;

      store.items.value = store.items.value.filter((modelInColl) => {
        return modelInColl.id !== model.id;
      });
    }, noop);

    return info;
  };

  return {
    ...store,
    insertItem,
    removeItem,
  };
}

/**
 * Removes the internal helpers from the store,
 * and returns it with some specific helpers.
 */
export function toPublicStore<DataType extends ModelItem, Extra extends object>(
  store: FullModelsStore<DataType>,
  extra: Extra,
): ModelsStore<DataType> & Extra {
  return {
    items: store.items,
    promise: store.promise,
    promiseInfo: store.promiseInfo,
    initialPromiseInfo: store.initialPromiseInfo,
    lastFetchedAt: store.lastFetchedAt,
    fetch: store.fetch,
    ensure: store.ensure,
    clearData: store.clearData,
    any: store.any,
    none: store.none,
    ...extra,
  };
}
