<template>
  <ViewComponent :order="order.item" :invoices="invoices" />
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/billing/orders/Show.vue";
import { useOrderStore } from "@/stores/order";
import { useOrderInvoicesStore } from "@/stores/order-invoices";

export default defineComponent({
  name: "OrderContainer",
  components: { ViewComponent },
  setup() {
    const orderStore = useOrderStore();
    const orderInvoicesStore = useOrderInvoicesStore();

    onBeforeMount(() => {
      orderStore.ensure({ staleAfter: "always" });
      orderInvoicesStore.ensure({ staleAfter: "always" });
    });

    return { orderStore, orderInvoicesStore };
  },
  computed: {
    order() {
      const { item, promiseInfo, any, none } = this.orderStore;
      return { item, latestFetch: promiseInfo, any, none };
    },

    invoices() {
      const { items, promiseInfo, any, none } = this.orderInvoicesStore;
      return { items, latestFetch: promiseInfo, any, none };
    },
  },
});
</script>
