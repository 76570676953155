export default {
  "notifications": {
    "databases": {
      "backups": {
        "manual": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup manuel réussi"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le backup manuel a été créé avec succès."])}
        }
      },
      "features": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration mise à jour"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été mise à jour avec succès."])}
      }
    },
    "addons": {
      "provisioned": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon provisionné avec succès."])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre ", _interpolate(_named("addon")), " addon a bien été provisionné."])}
      },
      "deleted": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon removed!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon was removed"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not remove addon."])}
        }
      },
      "resumed": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon resumed!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon was successfuly resumed."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Could not resume addon: ", _interpolate(_named("message"))])}
        }
      }
    },
    "handlers": {
      "billingProfile": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le profil a été créé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de créer le profil"])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le profil a été mis à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour le profil"])}
          }
        }
      },
      "hdsContact": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de contact professionnel de santé a été mis à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour votre contact HDS."])}
          }
        },
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre contact a été créé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de créer votre contact."])}
          }
        },
        "touch": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de contact professionnel de santé a été mis à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'exécuter l'action."])}
          }
        }
      },
      "account": {
        "deletion": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte supprimé avec succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande de suppression a été acceptée et est en train d'être traitée."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été mis à jour."])}
          }
        },
        "emailChanged": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez du courrier!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un mail de confirmation vient d'être envoyé à ", _interpolate(_named("email")), ". Cliquez sur le lien pour valider cette adresse email."])}
          }
        },
        "passwordChanged": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe changé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été changé"])}
          }
        }
      },
      "autoscaler": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler activé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'autoscaling est activé pour le container ", _interpolate(_named("container")), ", variant de ", _interpolate(_named("min")), " à ", _interpolate(_named("max")), "."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler mis à jour !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'autoscaler pour le container ", _interpolate(_named("container")), " a été mis à jour, variant de ", _interpolate(_named("min")), " à ", _interpolate(_named("max")), "."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler supprimé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'autoscaler pour le ", _interpolate(_named("container")), " a bien été supprimé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de supprimer l'autoscaler pour le container ", _interpolate(_named("container")), "."])}
          }
        },
        "disable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler désactivé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'autoscaler pour le ", _interpolate(_named("container")), " a bien été désactivé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de désactiver l'autoscaler pour le container ", _interpolate(_named("container")), "."])}
          }
        },
        "enable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler activé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'autoscaler pour le ", _interpolate(_named("container")), " a bien été activé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible d'activer l'autoscaler pour le container ", _interpolate(_named("container")), "."])}
          }
        }
      },
      "alerts": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte créée !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'alerte a bien été créée."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte supprimée !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'alerte a bien été supprimée."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'alerte n'a pas pu être supprimée."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte mise à jour !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'alerte a bien été mise à jour."])}
          }
        }
      },
      "containers": {
        "restart": {
          "generic": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conteneurs n'ont pas pu être redémarrés."])}
            }
          },
          "restarting": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conteneurs sont en cours de redémarrage."])}
            }
          }
        },
        "scale": {
          "generic": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conteneurs n'ont pas pu être scalés."])}
            }
          },
          "free-trial": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le⋅la propriétaire (", _interpolate(_named("owner")), ") doit mettre fin à l'essai gratuit pour procéder à cette action."])}
            }
          },
          "scaling": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conteneurs sont en cours de scaling."])}
            }
          }
        }
      },
      "collaborationLink": {
        "copy": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien d'invitation a été copié dans le presse-papier."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier dans le presse-papier."])}
          }
        }
      },
      "gitRemote": {
        "copy": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'URL du git remote a été copié dans le presse-papier."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier dans le presse-papier."])}
          }
        }
      },
      "collaborator": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateur invité !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le collaborateur a été invité."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'inviter le collaborateur."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateur supprimé !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le collaborateur a été supprimé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le collaborateur. Est-ce que le collaborateur lie l'app au SCM ?"])}
          }
        }
      },
      "data-access-consent": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentement accepté !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le consentement pour l'accès aux données a été accepté."])}
          }
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accepter le consentement."])}
        }
      },
      "contracts": {
        "accept": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat accepté !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contrat a été accepté."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accepter le contrat."])}
          }
        }
      },
      "domains": {
        "setDefault": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine rendu canonique !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine a été rendu canonique."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de rendre le domaine canonique."])}
          }
        },
        "unsetDefault": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine rendu non canonique !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine n'est plus canonique."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de rendre le domaine non canonique."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine supprimé !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine a été supprimé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le domaine."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat SSL mis à jour !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos certificat et clé ont bien été mis à jour."])}
          }
        }
      },
      "app": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application supprimée !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'application ", _interpolate(_named("name")), " a bien été supprimée."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application n'a pas pu être supprimée !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'application ", _interpolate(_named("name")), " n'a pas pu être supprimée."])}
          }
        },
        "rename": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application a été renommée !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'application a bien été renommée en ", _interpolate(_named("name")), "."])}
          }
        },
        "transfer": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application transférée !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'application ", _interpolate(_named("app")), " a bien été transférée à ", _interpolate(_named("owner")), "."])}
          }
        }
      },
      "appSettings": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application mise à jour !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres de routing ont bien été mis à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres de routing n'ont pas été mis à jour."])}
          }
        }
      },
      "appStackSettings": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application mise à jour !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres de stack ont bien été mis à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres de stack n'ont pas été mis à jour."])}
          }
        }
      },
      "deployments": {
        "manual": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement impossible !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le déploiement manuel a échoué."])}
          }
        }
      },
      "deploymentCache": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache supprimé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cache de déploiement a été supprimé avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cache de déploiement n'a pu être supprimé."])}
          }
        }
      },
      "notifiers": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieur créé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' a bien été créé."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieur supprimé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' a bien été supprimé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' n'a pas pu être supprimé."])}
          }
        },
        "disable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieur désactivé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' a été désactivé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' n'a pas pu être désactivé."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieur mis à jour !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' a bien été mis à jour."])}
          }
        },
        "enable": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieur activé !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' a été activé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["'", _interpolate(_named("name")), "' n'a pas pu être activé."])}
          }
        }
      },
      "paymentMethod": {
        "card": {
          "create": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte enregistrée !"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a bien été enregistrée."])}
            }
          },
          "delete": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte supprimée !"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a bien été supprimée."])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte n'a pas pu être supprimée."])}
            }
          },
          "setDefault": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte n'a pas pu être configurée comme carte par défaut."])}
            }
          }
        },
        "sepa": {
          "create": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte SEPA enregistré !"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte SEPA a bien été enregistré."])}
            }
          },
          "delete": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte SEPA supprimé !"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte SEPA a bien été supprimé."])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte SEPA n'a pas pu être supprimé."])}
            }
          },
          "setDefault": {
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte SEPA n'a pas pu être configuré comme compte par défaut."])}
            }
          }
        }
      },
      "paypal": {
        "initiate": {
          "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal n'a pas pu être contacté."])}
        },
        "validate": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Paypal effectuée !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le solde de votre compte a été mis à jour en conséquence."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la transaction Paypal !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors de la transaction."])}
          }
        }
      },
      "reviewApps": {
        "manual": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de déployer cette review app"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le déploiement de cette review app n'a pas fonctionné."])}
          }
        },
        "free-trial": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas déployer de review apps"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le·la propriétaire (", _interpolate(_named("owner")), ") doit mettre fin à l'essai gratuit pour déployer des review apps."])}
          }
        }
      },
      "scmIntegrations": {
        "link": {
          "github": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte github.com réussie !"])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion impossible"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de se connecter à votre compte github.com"])}
            },
            "already_linked": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte déjà connecté"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte Github est déjà lié à un autre compte Scalingo"])}
            },
            "linked_to_another_account": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à un autre compte"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte github.com est lié à un autre compte Scalingo"])}
            }
          },
          "gitlab": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitLab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte gitlab.com réussie !"])}
            },
            "error": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion impossible"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de se connecter à votre compte gitlab.com"])}
            },
            "already_linked": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte déjà connecté"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte Gitlab est déjà lié à un autre compte Scalingo"])}
            },
            "linked_to_another_account": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à un autre compte"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte gitlab.com est lié à un autre compte Scalingo"])}
            }
          }
        },
        "create": {
          "github": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte GitHub Enterprise réussie !"])}
            }
          },
          "gitlab": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitLab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte GitLab self-hosted réussie !"])}
            }
          }
        },
        "delete": {
          "github": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitHub"])}
            }
          },
          "gitlab": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de Gitlab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitLab"])}
            }
          },
          "github-enterprise": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de GitHub"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitHub Enterprise"])}
            }
          },
          "gitlab-self-hosted": {
            "success": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de Gitlab"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitLab self-hosted"])}
            }
          }
        }
      },
      "scmRepoLink": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépot désassocié !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dépot de code a bien été désassocié de votre app"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dépot de code n'a pas pu être désassocié de votre app"])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépot associé !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre dépot de code a bien été associé à votre app"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("error"))])}
          }
        }
      },
      "tokens": {
        "create": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le token '", _interpolate(_named("name")), "' n'a pas pu être créé."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token supprimé"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le token '", _interpolate(_named("name")), "' a bien été supprimé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le token '", _interpolate(_named("name")), "' n'a pas pu être supprimé."])}
          }
        }
      },
      "sshKeys": {
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé SSH crée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clé a été créée."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clé SSH n'a pas pu être créée."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé SSH supprimée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clé SSH a été supprimée."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clé SSH n'a pas pu être supprimée."])}
          }
        }
      },
      "user": {
        "stopFreeTrial": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période d'essai gratuite est terminée."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre un terme à la période d'essai gratuite."])}
          }
        }
      },
      "userPreferences": {
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences ont été mises à jour !"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences n'ont pas pu être mises à jour."])}
          }
        }
      },
      "variable": {
        "copy": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La variable et sa valeur ont bien été copiées dans le presse-papiers"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier dans le presse-papiers"])}
          }
        },
        "create": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable crée !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La variable '", _interpolate(_named("name")), "' a bien été créée."])}
          }
        },
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable supprimée !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La variable '", _interpolate(_named("name")), "' a bien été supprimée."])}
          }
        },
        "edit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable mise à jour !"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La variable '", _interpolate(_named("name")), "' a bien été mise à jour."])}
          }
        },
        "bulkEdit": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables mises à jour !"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les variables d'environnement ont bien été mises à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé durant la mise à jour des variables."])}
          }
        }
      }
    }
  }
}