<template>
  <div class="bg-scale-0 border-t-4 rounded px-6 py-4" :class="styling">
    <div class="flex flex-col sm:flex-row justify-between">
      <div class="flex mb-2">
        <div class="flex-shrink">
          <ParentAppLink class="mt-4" />
        </div>
        <div class="flex-grow text-2xl font-bold truncate text-scale-10">
          <div class="mb-2">
            <router-link
              :to="routerArgs"
              :title="app.name"
              class="text-base text-scale-4 font-medium hover:underline"
            >
              {{ app.name }}
            </router-link>
          </div>

          <div class="flex flex-col lg:flex-row space-x-2 items-baseline">
            <span>{{ databaseName(db.type_name) }}</span>

            <template
              v-if="db.status === 'running' && dbVersion.promiseInfo.isSuccess"
            >
              <span class="text-scale-6 text-lg font-medium" :title="version">
                {{ readableVersion(currentDbVersion) }}
              </span>
              <div
                v-if="
                  nextDbVersion &&
                  db.status === 'running' &&
                  !hideToAllowRelease
                "
                class="flex text-base text-color-2 underline cursor-pointer"
                @click="$emit('startDBUpgrade', { db, dbVersion })"
              >
                <div
                  v-if="!onlyBuildChanged"
                  class="flex flex-row items-baseline"
                >
                  <div class="font-medium">
                    {{
                      $t("updateAvailable", {
                        version: readableVersion(nextDbVersion),
                      })
                    }}
                  </div>
                </div>
                <div v-else class="flex flex-row items-baseline">
                  <div class="font-medium">
                    {{
                      $t("buildAvailable", {
                        from: readableFullVersion(currentDbVersion),
                        to: readableFullVersion(nextDbVersion),
                      })
                    }}
                  </div>
                </div>
              </div>
            </template>
            <Pill v-if="db.status === 'stopped'" type="danger">{{
              $t("status.stopped")
            }}</Pill>
          </div>
        </div>
      </div>
      <div
        v-if="db.status === 'running'"
        class="flex justify-items-auto items-center"
      >
        <div class="flex flex-col">
          <span
            class="text-sm font-medium pt-2 uppercase tracking-wide text-scale-5 sm:text-right"
            >{{ $t("plan") }}
          </span>
          <div
            class="font-medium flex flex-row items-center justify-end mt-1 humanize space-x-2"
          >
            <span>{{ addon.plan.display_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ParentAppLink from "@/components/atoms/glyphs/ParentAppLink.vue";
import Pill from "@/components/atoms/pills/Pill.vue";
import { databaseName } from "@/lib/utils/databaseName";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DbCartridge",
  components: { Pill, ParentAppLink },
  props: {
    app: Object,
    addon: Object,
    db: Object,
    dbVersion: Object,
  },
  emits: ["startDBUpgrade"],
  data() {
    return {
      // we will hide update+upgrade features for now, to allow public release
      // later, these features will be transferred into a dedicated page in settings
      // and the code around update/upgrade should be moved/deleted
      hideToAllowRelease: true,
    };
  },
  computed: {
    styling() {
      const status = this.db.status;
      if (status === "creating") {
        return "border-primary-2";
      }
      if (
        status === "updating" ||
        status === "upgrading" ||
        status === "restarting" ||
        status === "migrating"
      ) {
        return "border-secondary-4";
      }
      if (status === "stopped") {
        return "border-color-2";
      }
      return "border-color-1";
    },
    version() {
      return this.db.readable_version.replace("-", " v");
    },
    currentDbVersion() {
      return this.dbVersion?.item;
    },
    nextDbVersion() {
      return this.currentDbVersion?.next_upgrade;
    },
    onlyBuildChanged() {
      const { currentDbVersion, nextDbVersion } = this;
      return (
        currentDbVersion &&
        nextDbVersion &&
        currentDbVersion.major === nextDbVersion.major &&
        currentDbVersion.minor === nextDbVersion.minor &&
        currentDbVersion.patch === nextDbVersion.patch &&
        currentDbVersion.build !== nextDbVersion.build
      );
    },
    routerArgs() {
      return {
        name: Routes.App.Overview,
        params: { region: this.app.region, id: this.app.name },
      };
    },
  },
  methods: {
    databaseName,
    readableVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch}`;
    },
    readableFullVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch} v${dbVersion.build}`;
    },
  },
});
</script>

<i18n>
en:
  region: "Region"
  plan: "Current plan"
  updateAvailable: "Update available: {version}"
  buildAvailable: "Build update available: {from} → {to}"
  upgradePlan: "Upgrade this plan"
  status:
    creating: "Creating"
    running: "Running"
    migrating: "Migrating"
    updating: "Updating"
    upgrading: "Upgrading"
    stopped: "Stopped"
fr:
  region: "Région"
  plan: "Plan courant"
  updateAvailable: "Mise à jour disponible: {version} "
  buildAvailable: "Mise à jour build disponible: {from} → {to}"
  upgradePlan: "Mettre à niveau ce plan"
  status:
    creating: "Création"
    running: "Fonctionnelle"
    migrating: "Migration"
    updating: "Mise à jour"
    upgrading: "Mise à niveau"
    stopped: "Arrêtée"
</i18n>
