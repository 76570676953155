import { defineStore } from "pinia";

import {
  useCollectionStore,
  toPublicStore,
} from "@/lib/pinia/use-collection-store";
import { currentAddon, currentDBClient } from "@/stores/current/db";

export const useDbUsersStore = defineStore("dbUsers", () => {
  const store = useCollectionStore({
    fetchPromise: async () => {
      const addon = await currentAddon();
      const client = await currentDBClient();

      return client.Users.for(addon.id);
    },
  });

  // note: the public/private interface of the store is not well designed and needs to be rethinked
  return toPublicStore(store, {
    insertItem: store.insertItem,
    removeItem: store.removeItem,
  });
});
