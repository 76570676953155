import { Addon as OriginalAddon } from "scalingo/lib/models/regional";

import { App } from "./apps";

type AdditionnalInfo = { app: App };

export type Addon = OriginalAddon & AdditionnalInfo;

export function modifyItem(item: OriginalAddon, app: App): Addon {
  return { ...item, app };
}

export function modifyCollection(items: OriginalAddon[], app: App): Addon[] {
  return items.map((addon) => modifyItem(addon, app));
}
