<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-50" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-scale-0 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6"
              :class="panelClasses"
            >
              <div
                v-if="closable"
                class="absolute top-0 right-0 hidden pt-5 pr-5 sm:block"
              >
                <button
                  type="button"
                  class="rounded-md bg-scale-0 text-scale-4 hover:text-scale-6 focus:outline-none"
                  @click="closeModal"
                >
                  <span class="sr-only">{{ $t("close") }}</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div class="flex border-b-2 border-scale-1 mb-6">
                <DialogTitle as="h3" class="font-bold text-lg mb-2">
                  <slot name="title"> </slot>
                </DialogTitle>
              </div>

              <slot></slot>
              <div v-if="displayCloseButton" class="flex">
                <SCButton
                  block
                  kind="primary"
                  size="lg"
                  class="flex flex-grow mt-4"
                  @click="closeModal"
                >
                  <slot name="closeText">
                    {{ $t("close") }}
                  </slot>
                </SCButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { defineComponent, ref, nextTick } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import { registerModalCloser } from "@/lib/modals";

export default defineComponent({
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    SCButton,
  },
  props: {
    panelClasses: { type: String, default: "sm:w-full sm:max-w-sm" },
    closable: { type: Boolean, default: true },
    displayCloseButton: { type: Boolean, default: false },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const isOpen = ref(true);

    async function closeModal() {
      await nextTick();
      if (!props.closable) return;

      isOpen.value = false;

      // 200 is the duration of the transition in the template
      setTimeout(() => emit("close"), 200);
    }

    registerModalCloser(closeModal);

    return { isOpen, closeModal };
  },
});
</script>

<i18n>
en:
  close: "Close"
  cancel: "Cancel"
fr:
  close: "Fermer"
  cancel: "Annuler"
</i18n>
