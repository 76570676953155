import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

import BackupsList from "@/controllers/db/backups/List.vue";
import BackupsRoot from "@/controllers/db/backups/Root.vue";
import DbMetrics from "@/controllers/db/DbMetrics.vue";
import DbOverview from "@/controllers/db/DbOverview.vue";
import DbQueryStatistics from "@/controllers/db/DbQueryStatistics.vue";
import DbRoot from "@/controllers/db/DbRoot.vue";
import DbRunningQueries from "@/controllers/db/DbRunningQueries.vue";
import DbLogsArchives from "@/controllers/db/logs/DbLogsArchives.vue";
import DbLogsLive from "@/controllers/db/logs/DbLogsLive.vue";
import DbLogsRoot from "@/controllers/db/logs/DbLogsRoot.vue";
import Configuration from "@/controllers/db/settings/Configuration.vue";
import DbMaintenance from "@/controllers/db/settings/DbMaintenance.vue";
import DbSettingsRoot from "@/controllers/db/settings/DbSettingsRoot.vue";
import DbGeneral from "@/controllers/db/settings/General.vue";
import InternetAccess from "@/controllers/db/settings/InternetAccess.vue";
import UserAccess from "@/controllers/db/settings/UserAccess.vue";
import i18n from "@/i18n";
import { AppDbRoutes as R } from "@/router/app/db/names";

export const appDbRoutes: RouteRecordRaw = {
  path: "db/:dbId",
  name: R.Root,
  component: DbRoot,
  redirect: (to) => {
    const { params, query } = to;
    return { name: R.Overview, params, query };
  },
  meta: {
    title: ({ params }: RouteLocationNormalized) => {
      return i18n.global.t("titles.db.root", {
        name: params.dbId,
      });
    },
  },
  children: [
    {
      path: "",
      name: R.Overview,
      component: DbOverview,
      meta: {
        title: i18n.global.t("titles.db.overview"),
      },
    },
    {
      path: "logs",
      name: R.Logs.Root,
      component: DbLogsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Logs.Live, params, query };
      },
      children: [
        {
          path: "",
          name: R.Logs.Live,
          component: DbLogsLive,
          meta: {
            title: i18n.global.t("titles.db.logs.live"),
          },
        },
        {
          path: "archives",
          name: R.Logs.Archives,
          component: DbLogsArchives,
          meta: {
            title: i18n.global.t("titles.db.logs.archives"),
          },
        },
      ],
    },
    {
      path: "backups",
      name: R.Backups.Root,
      component: BackupsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Backups.List, params, query };
      },
      children: [
        {
          path: "list",
          name: R.Backups.List,
          component: BackupsList,
          meta: {
            title: i18n.global.t("titles.db.backups"),
          },
        },
      ],
    },
    {
      path: "running-queries",
      name: R.RunningQueries,
      component: DbRunningQueries,
      meta: {
        title: i18n.global.t("titles.db.runningQueries"),
      },
    },
    {
      path: "query-statistics",
      name: R.QueryStatistics,
      component: DbQueryStatistics,
      meta: {
        title: i18n.global.t("titles.db.queryStatistics"),
      },
    },
    {
      path: "metrics",
      name: R.Metrics,
      component: DbMetrics,
      meta: {
        title: i18n.global.t("titles.db.metrics"),
      },
    },
    {
      path: "settings",
      name: R.Settings,
      component: DbSettingsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.General, params, query };
      },
      children: [
        {
          path: "general",
          name: R.General,
          component: DbGeneral,
          meta: {
            title: i18n.global.t("titles.db.settings.general"),
          },
        },
        {
          path: "maintenance",
          name: R.Maintenance,
          component: DbMaintenance,
          meta: {
            title: i18n.global.t("titles.db.settings.maintenance"),
          },
        },
        {
          path: "networking",
          name: R.Networking,
          component: InternetAccess,
          meta: {
            title: i18n.global.t("titles.db.settings.networking"),
          },
        },
        {
          path: "configuration",
          name: R.Configuration,
          component: Configuration,
          meta: {
            title: i18n.global.t("titles.db.settings.configuration"),
          },
        },
        {
          path: "users",
          name: R.UserAccess,
          component: UserAccess,
          meta: {
            title: i18n.global.t("titles.db.settings.users"),
          },
        },
      ],
    },
  ],
};
