import { defineStore } from "pinia";
import { SCMIntegration } from "scalingo/lib/models/auth";
import { CreateParams } from "scalingo/lib/params/auth/scm_integrations";

import {
  useCollectionStore,
  toPublicStore,
} from "@/lib/pinia/use-collection-store";
import { useSessionStore } from "@/stores/session";

export const useScmIntegrationsStore = defineStore("scmIntegrations", () => {
  const { client } = useSessionStore();

  const store = useCollectionStore({
    fetchPromise: () => client().SCMIntegrations.all(),
  });

  const { insertItem, removeItem } = store;

  return toPublicStore(store, {
    create(payload: CreateParams) {
      return insertItem(client().SCMIntegrations.create(payload));
    },
    destroy(integration: SCMIntegration) {
      return removeItem(
        integration,
        client().SCMIntegrations.destroy(integration.id),
      );
    },
  });
});
