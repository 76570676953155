<template>
  <ViewComponent
    :owner="app.owner"
    :loadingOperation="loadingOperation"
    :selectedAddonProvider="selectedAddonProvider"
    :selectedAddonPlan="selectedAddonPlan"
    :createCtx="createCtx"
    :displayPrices="!app.c3_resource"
    @changeAddonProvider="changeAddonProvider"
    @changeAddonPlan="changeAddonPlan"
    @provisionningRequested="provisionningRequested"
  ></ViewComponent>
</template>

<script>
import { APIError } from "scalingo/lib/errors";
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/addonsCreation/Step3.vue";
import { voidPromiseInfo } from "@/lib/promises/info";
import { errorsMeta } from "@/lib/scalingo/errors";
import { Routes } from "@/router/names";
import { availableAddonProviders } from "@/store/addon-providers";
import { billingProfile } from "@/store/billing-profile";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "AddonCreationStep3Container",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      createCtx: { info: voidPromiseInfo() },
    };
  },
  computed: {
    addonsStore() {
      return useAppAddonsStore();
    },
    loadingOperation() {
      return this.addonProviders.latestFetch;
    },
    addons() {
      return {
        items: this.addonsStore.items,
        promiseInfo: this.addonsStore.promiseInfo,
      };
    },
    billingProfile() {
      return billingProfile(this.$store);
    },
    addonProviders() {
      return availableAddonProviders(
        this.$store,
        this.app.region,
        this.addons.items,
      );
    },
    selectedAddonProvider() {
      return availableAddonProviders(
        this.$store,
        this.app.region,
        this.addons.items,
      ).items.find((provider) => {
        return provider.id === this.$route.params.providerId;
      });
    },
    selectedAddonPlan() {
      return this.selectedAddonProvider?.plans?.find((plan) => {
        return plan.name === this.$route.params.planId;
      });
    },
  },
  methods: {
    changeAddonProvider() {
      this.$router.push({
        name: Routes.App.Addon.Creation.Step1,
        params: {
          id: this.$route.params.id,
          region: this.$route.params.region,
        },
      });
    },
    changeAddonPlan() {
      this.$router.push({
        name: Routes.App.Addon.Creation.Step2,
        params: {
          id: this.$route.params.id,
          region: this.$route.params.region,
          providerId: this.$route.params.providerId,
        },
      });
    },
    async provisionningRequested() {
      this.createCtx = { info: voidPromiseInfo(), errors: {} };

      const payload = {
        plan_id: this.selectedAddonPlan.id,
        addon_provider_id: this.selectedAddonProvider.id,
      };

      try {
        this.createCtx.info = this.addonsStore.create(payload);

        await this.createCtx.info.promise;

        this.$router.push({
          name: Routes.App.Resources,
          params: {
            id: this.$route.params.id,
            region: this.$route.params.region,
          },
        });
      } catch (err) {
        this.createCtx.errors.meta = errorsMeta(err);

        if (err instanceof APIError) {
          if (err.data?.message) {
            this.createCtx.errors.message = err.data.message;
          }
        }
      }
    },
  },
});
</script>
