import { noop } from "lodash";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { useResourceStore } from "@/lib/pinia/use-resource-store";
import { clearPollingFor } from "@/lib/pinia/utils/polling";
import { enhanceApp } from "@/lib/scalingo/modifiers/apps";
import {
  initDeploymentsStreamer,
  teardownDeploymentsStreamer,
} from "@/lib/scalingo/streamers/deployments";
import { teardownLogsStreamer } from "@/lib/scalingo/streamers/logs";
import { ApplicationStore } from "@/store";
import { Alerts } from "@/store/alerts";
import { AuditLogs } from "@/store/audit-logs";
import { Autoscalers } from "@/store/autoscalers";
import { Collaborators } from "@/store/collaborators";
import { Containers } from "@/store/containers";
import { CronTasks } from "@/store/cron-tasks";
import { DeploymentLogs } from "@/store/deployment-logs";
import { Deployments } from "@/store/deployments";
import { Domains } from "@/store/domains";
import { Events } from "@/store/events";
import { LogArchives } from "@/store/log-archives";
import { Logs } from "@/store/logs";
import { Notifiers } from "@/store/notifiers";
import { Operations } from "@/store/operations";
import { ReviewApps } from "@/store/review-apps";
import { ScmRepoLink } from "@/store/scm-repo-link";
import { Variables } from "@/store/variables";
import { useAppAddonsStore } from "@/stores/app/addons";
import { useAppInfosStore } from "@/stores/app-infos";
import { useSessionStore } from "@/stores/session";

/**
 * The current app store behaves mostly like a regular resource store,
 * except that it'll store the region + app id (which are needed for the fetch)
 * and won't expose fetch/ensure
 */
export const useCurrentAppStore = defineStore("currentApp", () => {
  const { client } = useSessionStore();
  const appInfosStore = useAppInfosStore();

  const appRegion = ref<string | null>(null);
  const appId = ref<string | null>(null);

  const { item, promiseInfo, fetch, clearData } = useResourceStore({
    fetchPromise: () => {
      if (!appRegion.value) throw new Error("no region in store");
      if (!appId.value) throw new Error("no app ID in store");

      return client(appRegion.value)
        .Apps.find(appId.value)
        .then((app) => enhanceApp(app));
    },
  });

  const regional = item;
  const regionalPromiseInfo = promiseInfo;

  const setCurrentApp = function (
    store: ApplicationStore, // the store argument will be removed when vuex is gone
    region: string,
    id: string,
  ) {
    // Same params? Do not re-do a switch
    if (region === appRegion.value && id === appId.value)
      return regionalPromiseInfo.value.promise;

    // First, clear the current store
    unsetCurrentApp(store);

    // Then fetch the target app, and act accordingly
    appRegion.value = region;
    appId.value = id;

    fetch();

    regionalPromiseInfo.value.promise.then(
      (app) => initDeploymentsStreamer(store, app),
      noop,
    );
  };

  // This function should clear the dependent stores as well
  function unsetCurrentApp(store: ApplicationStore) {
    // Clear all sub-resources
    useAppAddonsStore().clearData();
    clearPollingFor("addon");

    store.dispatch(Alerts.actions.CLEAR);
    store.dispatch(AuditLogs.actions.CLEAR);
    store.dispatch(Autoscalers.actions.CLEAR);
    store.dispatch(Collaborators.actions.CLEAR);
    store.dispatch(Domains.actions.CLEAR);
    store.dispatch(Containers.actions.CLEAR);
    store.dispatch(CronTasks.actions.CLEAR);
    store.dispatch(Deployments.actions.CLEAR);
    store.dispatch(DeploymentLogs.actions.CLEAR);
    store.dispatch(Events.actions.CLEAR);
    store.dispatch(Logs.actions.CLEAR);
    store.dispatch(LogArchives.actions.CLEAR);
    store.dispatch(Operations.actions.CLEAR);
    store.dispatch(Notifiers.actions.CLEAR);
    store.dispatch(ScmRepoLink.actions.CLEAR);
    store.dispatch(ReviewApps.actions.CLEAR);
    store.dispatch(Variables.actions.CLEAR);

    if (regional.value) {
      teardownDeploymentsStreamer(regional.value);
      teardownLogsStreamer(regional.value);
    }

    clearData();

    appRegion.value = null;
    appId.value = null;
  }

  const appInfo = computed(() => {
    return appInfosStore.items?.find((app) => {
      return (
        app.region == appRegion.value &&
        (app.id === appId.value || app.name === appId.value)
      );
    });
  });

  const appInfoPromiseInfo = computed(() => {
    return appInfosStore.promiseInfo;
  });

  const appInfoOrFull = computed(() => {
    return appInfo.value || regional.value;
  });

  const isSet = computed(() => {
    return !!appRegion.value && !!appId.value;
  });

  return {
    regional,
    regionalPromiseInfo,
    appInfo,
    appInfoPromiseInfo,
    appInfoOrFull,
    isSet,
    setCurrentApp,
    unsetCurrentApp,
    refresh: fetch,
  };
});

export function currentApp() {
  const appStore = useCurrentAppStore();
  const app = appStore.regional;

  if (!app) throw new Error("Expected current app to be present");

  return app;
}
