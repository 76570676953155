import { FormHandler } from "@/lib/handlers/form";
import { Contact as HDSContact, UpdateParams } from "@/lib/scalingo/contacts";
import { Nullable } from "@/lib/utils/types";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";

import type { ComponentPublicInstance } from "vue";

export class EditHDSContactHandler extends FormHandler<HDSContact> {
  keyPath = "hdsContact.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly contact: HDSContact,
  ) {
    super(component);
  }

  data(): Nullable<UpdateParams> {
    return {
      kind: this.contact.kind || null,
      entity: this.contact.entity || null,
      firstname: this.contact.firstname || null,
      lastname: this.contact.lastname || null,
      email: this.contact.email || null,
      phone: this.contact.phone || null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(event: UpdateParams): Promise<void> {
    const store = usePointOfContactStore();

    this.followPinia(store.update(event));
  }
}
