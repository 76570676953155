<template>
  <div
    class="py-3 flex flex-col pv:flex-row"
    :class="{ 'border-t border-scale-2': rowIndex > 0 }"
  >
    <div class="flex flex-grow items-center justify-start">
      <div class="pr-2 self-center">
        <AddonIcon :addon="addon" :width="32" />
      </div>

      <div class="ml-1 font-medium">
        {{ addon.addon_provider.name }}
      </div>
      <div class="text-scale-5 pl-1">({{ addon.plan.display_name }})</div>
    </div>

    <div class="flex items-center justify-center py-2 pv:py-0 pv:justify-end">
      <SCButton
        v-if="addon.status == 'running'"
        kind="neutral"
        class="ml-3"
        :actions="newDashActions(addon)"
        @click="openDashboard(addon)"
      >
        {{ $t("actions.dashboard") }}

        <font-awesome-icon class="ml-1" icon="external-link-alt" />
      </SCButton>

      <SCButton
        v-else-if="addon.status === 'provisioning'"
        kind="neutral"
        class="ml-3"
        loading
      >
        {{ $t("actions.dashboard") }}

        <font-awesome-icon class="ml-1" icon="external-link-alt" />
      </SCButton>

      <a ref="addon-link" class="hidden" href="#" target="_blank"></a>

      <Spinner class="ml-4 mr-2" :class="!addonIsLoading ? 'hidden' : null" />

      <HorizontalDottedDropdown
        v-if="!addonIsLoading"
        class="ml-4"
        :actions="actions"
        @action="(e) => actionClicked(addon, e)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import HorizontalDottedDropdown from "@/components/molecules/dropdowns/HorizontalDottedDropdown.vue";
import AddonIcon from "@/components/organisms/addons/Icon.vue";
import { scalingoClient } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";

export default defineComponent({
  name: "AddonsCard",
  components: {
    AddonIcon,
    SCButton,
    HorizontalDottedDropdown,
    Spinner,
  },
  props: {
    app: Object,
    user: Object,
    addon: Object,
    busyAddons: Object,
    rowIndex: Number,
  },
  emits: ["resumeAddon", "startAddonDeletion"],
  computed: {
    addonIsLoading() {
      return this.busyAddons[this.addon.id];
    },
    actions() {
      const actions = [];

      if (this.addon.status === "running") {
        actions.push({
          text: this.$i18n.t("actions.changePlan"),
          key: "changePlan",
        });
      }

      if (this.addon.status === "suspended") {
        actions.push({ text: this.$i18n.t("actions.resume"), key: "resume" });
      }

      actions.push({
        text: this.$i18n.t("actions.destroy"),
        key: "destroy",
        kind: "warning",
      });

      return actions;
    },
  },
  methods: {
    dashboardLinkFor(addon) {
      return {
        noRouter: true,
        tagAttrs: { href: addon.sso_url || "#", target: "_blank" },
      };
    },
    newDashActions(addon) {
      if (["redis", "postgresql"].includes(addon.addon_provider.id)) {
        return [
          {
            text: this.$t("dashboard.new"),
            to: {
              name: Routes.App.Db.Root,
              params: {
                region: this.app.region,
                id: this.app.name,
                dbId: addon.addon_provider.id,
              },
            },
          },
        ];
      } else if (
        [""].includes(addon.addon_provider.id) &&
        userIsAdminOrHasFlag(this.user, "employee")
      ) {
        return [
          {
            text: this.$t("dashboard.new"),
            to: {
              name: Routes.App.Db.Root,
              params: {
                region: this.app.region,
                id: this.app.name,
                dbId: addon.addon_provider.id,
              },
            },
          },
        ];
      }
    },
    actionClicked(addon, { action }) {
      if (action.key === "changePlan") {
        this.$router.push({
          name: Routes.App.Addon.Edition.Root,
          params: {
            id: this.app.name,
            region: this.app.region,
            addonId: addon.id,
          },
        });
        return;
      }

      if (action.key === "resume") {
        this.$emit("resumeAddon", addon);
        return;
      }

      if (action.key === "destroy") {
        this.$emit("startAddonDeletion", { addon });
        return;
      }
    },
    async openDashboard(addon) {
      let addonResponse = await scalingoClient(
        this.$store,
        this.app.region,
      ).Addons.sso(addon.app_id, addon.id);

      const target = this.$refs["addon-link"];

      target.setAttribute("href", addonResponse.sso_url);
      target.click();
      target.setAttribute("href", "#");
    },
  },
});
</script>

<i18n>
en:
  actions:
    dashboard: "Go to dashboard"
    changePlan: "Change plan"
    resume: "Resume"
    destroy: "Remove"
  dashboard:
    new: "New version (preview)"
fr:
  actions:
    dashboard: "Aller au dashboard"
    changePlan: "Changer de plan"
    resume: "Relancer"
    destroy: "Supprimer"
  dashboard:
    new: "Nouvelle version (preview)"
</i18n>
