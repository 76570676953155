export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "actions": {
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to dashboard"])},
          "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change plan"])},
          "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
          "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
        },
        "dashboard": {
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version (preview)"])}
        }
      },
      "fr": {
        "actions": {
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au dashboard"])},
          "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de plan"])},
          "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relancer"])},
          "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
        },
        "dashboard": {
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle version (preview)"])}
        }
      }
    }
  })
}
