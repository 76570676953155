export const AppDbRoutes = {
  Root: "DBRoot",
  Overview: "DBOverview",
  Metrics: "DBMetrics",
  Settings: "DBSettingsRoot",
  Networking: "DBNetworking",
  Configuration: "DBConfiguration",
  General: "DBSettingsGeneral",
  Maintenance: "DBMaintenance",
  UserAccess: "DBUserAccess",
  RunningQueries: "DbRunningQueries",
  QueryStatistics: "DbQueryStatistics",
  Logs: {
    Root: "DBLogsRoot",
    Live: "DBLogsLive",
    Archives: "DBLogsArchives",
  },
  Backups: {
    Root: "DBBackupsRoot",
    List: "DBBackupsList",
  },
};
