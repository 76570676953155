import { localize } from "@vee-validate/i18n";
import en from "@vee-validate/i18n/dist/locale/en.json";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
import {
  required,
  email,
  not_one_of,
  is,
  min,
  min_value,
  max,
  max_value,
  integer,
  alpha_dash,
} from "@vee-validate/rules";
import IBAN from "iban";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { merge } from "lodash";
import isUrl from "validator/lib/isURL";
import { configure, defineRule } from "vee-validate";

import enCustom from "@/lib/validations/en.json";
import frCustom from "@/lib/validations/fr.json";

merge(en.messages, enCustom.messages);
merge(fr.messages, frCustom.messages);

configure({
  generateMessage: localize({
    en,
    fr,
  }),
});

const APP_NAME_REGEXP = /^[a-z0-9-]+$/;
const EU_COUNTRY_CODES = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
];

defineRule("email", email);
defineRule("excluded", not_one_of);
defineRule("is", is);
defineRule("required", required);
defineRule("min", min);
defineRule("min_value", min_value);
defineRule("max", max);
defineRule("max_value", max_value);
defineRule("integer", integer);
defineRule("alpha_dash", alpha_dash);

defineRule("phone", (value: string) => {
  let parsed;

  try {
    parsed = parsePhoneNumber(value, {
      defaultCountry: "FR",
    });
  } catch {
    return false;
  }

  if (EU_COUNTRY_CODES.includes(parsed.country || "") === false) {
    return false;
  }

  return isValidPhoneNumber(value, parsed.country);
});

defineRule("passwordConfirmation", (value: string, targets: string[]) => {
  return value === targets[0];
});

defineRule("appName", (value: string) => {
  return APP_NAME_REGEXP.test(value);
});

defineRule("noStartHyphen", (value: string) => {
  return value[0] !== "-";
});

defineRule("noEndHyphen", (value: string) => {
  return value[value.length - 1] !== "-";
});

defineRule("noConsecutiveHyphens", (value: string) => {
  return value.indexOf("--") === -1;
});

defineRule("iban", (value: string) => {
  return IBAN.isValid(value);
});

defineRule("url", (value: string) => {
  return isUrl(value, {
    protocols: ["http", "https"],
    require_protocol: true,
  });
});

// valid if first letter is neither a digit nor underscore
// valid if string isn't a dash " - ", value allowed by built in alpha_dash validator
defineRule("databaseUsername", (value: string) => {
  return (
    !Number.isFinite(Number.parseInt(value[0])) &&
    value[0] !== "_" &&
    !value.includes("-")
  );
});
