<template>
  <div>
    <DbCartridge
      v-if="withCartridge"
      :app="app"
      :db="db"
      :addon="null"
      :dbVersion="null"
    ></DbCartridge>
    <CreatingBanner
      v-if="db.status === 'creating'"
      class="mt-4"
    ></CreatingBanner>
    <UpgradeBanner
      v-if="db.status === 'upgrading'"
      class="mt-4"
    ></UpgradeBanner>
    <UpdateBanner v-if="db.status === 'updating'" class="mt-4"></UpdateBanner>
    <RestartingBanner
      v-if="db.status === 'restarting'"
      class="mt-4"
    ></RestartingBanner>
    <MigratingBanner
      v-if="db.status === 'migrating'"
      class="mt-4"
    ></MigratingBanner>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CreatingBanner from "@/components/organisms/banners/CreatingBanner.vue";
import MigratingBanner from "@/components/organisms/banners/MigratingBanner.vue";
import RestartingBanner from "@/components/organisms/banners/RestartingBanner.vue";
import UpdateBanner from "@/components/organisms/banners/UpdateBanner.vue";
import UpgradeBanner from "@/components/organisms/banners/UpgradeBanner.vue";
import DbCartridge from "@/components/parts/db/dbOverview/DbCartridge.vue";

export default defineComponent({
  name: "DbNotRunning",
  components: {
    CreatingBanner,
    RestartingBanner,
    MigratingBanner,
    UpdateBanner,
    UpgradeBanner,
    DbCartridge,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
    withCartridge: {
      type: Object,
      required: true,
    },
  },
  computed: {},
});
</script>
