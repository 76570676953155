import LogsListener from "scalingo/lib/Logs/listener";
import { Addon, App } from "scalingo/lib/models/regional";
import { ActionContext } from "vuex";

import { RootState } from "@/lib/store/utils";
import { dashboard } from "@/lib/utils/log";
import { Logs } from "@/store/logs";
import { SessionState } from "@/store/session";
import { useDbLogsStore } from "@/stores/db/logs";

import { scalingoClient } from "../client";
import { scalingoDBClient } from "../dbapi/client";
import { Db } from "../dbapi/database";

interface ListenerInfo {
  /* Wrapper for the logs WS */
  listener: LogsListener;
  /* Each incoming log line ends up in this buffer */
  buffer: string[];
  /* ID of the setInterval that pushes the buffer's content to the logs store */
  intervalId: NodeJS.Timeout | null;
}

// A "global" dict for all opened streamers.
// There shouldn't be more than one opened at a time,
// but it makes the logic similar to deployments streamer and
// makes it easy to ensure we don't re-open a log streamer for a given app
const streamers: Record<string, ListenerInfo> = {};

const dbStreamers: Record<string, ListenerInfo> = {};

// Buffering per 200ms should be good enough to increase perfs while not feeling too "batch per batch"
const intervalDelay = 200;

export async function initLogsStreamer(
  sessionContext: ActionContext<SessionState, RootState>,
  app: App,
): Promise<ListenerInfo> {
  let listenerInfo: ListenerInfo | void = streamers[app.id];

  if (listenerInfo) {
    dashboard.log(`logs streamer for ${app.name} already initiated`);
    return listenerInfo;
  }

  dashboard.log(`init logs streamer for ${app.name}`);

  const client = scalingoClient(sessionContext, app.region);
  const listener = await client.Logs.listenerFor(app.id);
  const buffer: string[] = [];

  // When we receive a line of log, we push it into the buffer
  listener.onLog((msg) => {
    buffer.push(msg);
  });

  // Periodically, we empty the buffer and batch inserts its content to the store
  const intervalId: NodeJS.Timeout = setInterval(() => {
    const contents = buffer.splice(0);

    if (contents.length > 0) {
      sessionContext.dispatch(Logs.actions.PUSH, contents, { root: true });
    }
  }, intervalDelay);

  listenerInfo = {
    listener,
    buffer,
    intervalId,
  };

  streamers[app.id] = listenerInfo;

  return listenerInfo;
}

export function teardownLogsStreamer(app: App): void {
  const listenerInfo = streamers[app.id];

  if (!listenerInfo) {
    dashboard.log(`logs streamer for ${app.name} not present, nothing to do`);
    return;
  }

  dashboard.log("teardown logs streamer");

  // empty the buffer, remove the periodic function call
  listenerInfo.buffer.splice(0);
  if (listenerInfo.intervalId) clearInterval(listenerInfo.intervalId);

  listenerInfo.listener?.close?.();

  delete streamers[app.id];
}

export async function initDbLogsStreamer(
  app: App,
  addon: Addon,
  database: Db,
): Promise<ListenerInfo> {
  let listenerInfo: ListenerInfo | void = dbStreamers[database.id];

  if (listenerInfo) {
    dashboard.log(
      `logs streamer for ${database.app_name}/${database.type_name} already initiated`,
    );
    return listenerInfo;
  }

  dashboard.log(
    `init logs streamer for ${database.app_name}/${database.type_name}`,
  );

  const client = await scalingoDBClient(app, addon.id);
  const listener = await client.Logs.listenerFor(database.id);
  const buffer: string[] = [];

  const store = useDbLogsStore();

  // When we receive a line of log, we push it into the buffer
  listener.onLog((msg) => {
    buffer.push(msg);
  });

  // Periodically, we empty the buffer and batch inserts its content to the store
  const intervalId: NodeJS.Timeout = setInterval(() => {
    const contents = buffer.splice(0);

    if (contents.length > 0) {
      store.pushWithCap(contents);
    }
  }, intervalDelay);

  listenerInfo = {
    listener,
    buffer,
    intervalId,
  };

  dbStreamers[database.id] = listenerInfo;

  return listenerInfo;
}

export function teardownDbLogsStreamer(database: Db): void {
  const listenerInfo = dbStreamers[database.id];

  if (!listenerInfo) {
    dashboard.log(
      `logs streamer for $${database.app_name}/${database.type_name} not present, nothing to do`,
    );
    return;
  }

  dashboard.log("teardown db logs streamer");

  // empty the buffer, remove the periodic function call
  listenerInfo.buffer.splice(0);
  if (listenerInfo.intervalId) clearInterval(listenerInfo.intervalId);

  listenerInfo.listener?.close?.();

  delete dbStreamers[database.id];
}
