<template>
  <ViewComponent
    :user="currentUser"
    :db="db"
    :app="app"
    :extensionList="extensionList"
    :cacheInfo="cacheInfo"
    :RDBInfo="RDBInfo"
    :AOFInfo="AOFInfo"
    :disableAOF="disableAOF"
    :enableAOF="enableAOF"
    :disableCache="disableCache"
    :enableCache="enableCache"
    :disableRDB="disableRDB"
    :enableRDB="enableRDB"
  ></ViewComponent>
</template>

<script>
import { computed, defineComponent } from "vue";

import ViewComponent from "@/components/views/db/settings/Configuration.vue";
import postgresExtensions from "@/controllers/db/settings/configuration/PostgresExtension.vue";
import redisPersistance from "@/controllers/db/settings/configuration/RedisPersistence.vue";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";

export default defineComponent({
  name: "Configuration",
  components: { ViewComponent },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const extensionData =
      currentDbStore.database.type_name === "postgresql"
        ? postgresExtensions.setup()
        : {};
    const persistenceData =
      currentDbStore.database.type_name === "redis"
        ? redisPersistance.setup()
        : {};

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
      extensionList: extensionData.extensionList,
      cacheInfo: persistenceData.cacheInfo,
      RDBInfo: persistenceData.RDBInfo,
      AOFInfo: persistenceData.AOFInfo,
      enableAOF: persistenceData.enableAOF,
      disableAOF: persistenceData.disableAOF,
      disableCache: persistenceData.disableCache,
      enableCache: persistenceData.enableCache,
      disableRDB: persistenceData.disableRDB,
      enableRDB: persistenceData.enableRDB,
    };
  },
});
</script>
