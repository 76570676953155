import { isObject } from "lodash";
import Rollbar from "rollbar";

import {
  Response,
  decodeToken,
  Middleware,
  shouldRefreshToken,
  ROUTE_PATH,
  OAuthAssistedMessage,
  MiddlewareError,
} from "@/lib/auth/utils";
import { debug } from "@/lib/utils/log";

// Possible errors
const OAUTH_ASSISTED_NO_TOKEN = { error: "oauth-assisted/no-token" };
const OAUTH_ASSISTED_UNREADABLE = { error: "oauth-assisted/unreadable" };
const OAUTH_ASSISTED_EXPIRED = { error: "oauth-assisted/expired" };
const OAUTH_ASSISTED_INCOMPATIBLE_DOMAINS = {
  error: "oauth-assisted/incompatible-domains",
};
const OAUTH_ASSISTED_IFRAME_TIMEOUT = {
  error: "oauth-assisted/iframe-timeout",
  reason: "timeout",
};
const OAUTH_ASSISTED_MESSAGE_TIMEOUT = {
  error: "oauth-assisted/message-timeout",
  reason: "timeout",
};

// Helpers
const IFRAME_TIMEOUT_DURATION = 5000; // milliseconds
const MESSAGE_TIMEOUT_DURATION = 2000;

/**
 * Performs the OAuth assisted token flow to login:
 * * Opens an iframe to the authentication service
 * * Wait for it to use postMessage to pass a bear token or an error message
 * * Look out for timeout possibilites, either from an iframe or for the postMessage
 */
export class LoginFromOAuthAssisted implements Middleware {
  name = "LoginFromOAuthAssisted";
  responseType = "token";
  prompt = "none";
  oauthRedirectUri: string;
  rollbar: Rollbar | null = null;

  constructor(
    readonly authBaseUrl: string,
    readonly oauthClientId: string,
  ) {
    this.oauthRedirectUri = window.location.origin;
  }

  get authIframeUrl(): string {
    return `${this.authBaseUrl}/oauth/assisted?client_id=${this.oauthClientId}&response_type=${this.responseType}&prompt=${this.prompt}`;
  }

  /**
   * Returns a promise that resolves when the ifame has loaded the given url.
   * If given an optional targetOrigin, use postMessage to let it know we're ready.
   */
  runIframeNavigation(
    iframe: HTMLIFrameElement,
    url: string,
    targetOrigin?: string,
  ): Promise<Event> {
    return new Promise((resolve, reject) => {
      // If the iframe takes too long to load, we trigger a timeout
      // This can happen on slow networks, but also if the iframe gets blocked.
      const timeoutId = setTimeout(() => {
        reject(OAUTH_ASSISTED_IFRAME_TIMEOUT);
      }, IFRAME_TIMEOUT_DURATION);

      iframe.onload = (loadEvent) => {
        // iframe loaded? remove the timeout rejection
        clearTimeout(timeoutId);

        // Lets the auth-service know we can receive messages
        if (targetOrigin) {
          iframe.contentWindow?.postMessage("ready", targetOrigin);
        }

        debug(`-> Auth/${this.name}: iframe loaded`, url);
        resolve(loadEvent);
      };

      iframe.setAttribute("src", url);
    });
  }

  /** Inserts an empty iframe into the DOM */
  prepareIframe(id = "auth-frame"): HTMLIFrameElement {
    const frame = document.createElement("iframe");

    frame.setAttribute("id", id);
    frame.setAttribute("aria-hidden", "true");
    frame.style.visibility = "hidden";
    frame.style.position = "absolute";
    frame.style.width = frame.style.height = frame.style.borderWidth = "0px";

    return document.getElementsByTagName("body")[0].appendChild(frame);
  }

  getToken(): Promise<Response> {
    return new Promise((resolve, reject) => {
      const messageListener = (event: MessageEvent<OAuthAssistedMessage>) => {
        // Message received? remove the timeout rejection
        clearTimeout(timeoutId);

        // Origins don't match? This does not come from the assisted iframe
        if (event.origin !== this.authBaseUrl) {
          debug(`-> Auth/${this.name}: wrong message origin, skipping`);
          return;
        }

        // Wrong format? Possibly an extension
        if (!isObject(event.data)) {
          debug(`-> Auth/${this.name}: message format error`, event);
          this.rollbar?.error(
            `-> Auth/${this.name}: message format error`,
            event,
          );
          return;
        }

        // Error message? Reject the promise
        if ("error" in event.data) {
          debug(`-> Auth/${this.name}: message error`, event.data);
          reject(event.data);
          return;
        }

        const token = event.data.assisted_token;

        // No token? Reject the promise
        if (!token) {
          reject(OAUTH_ASSISTED_NO_TOKEN);
          return;
        }

        try {
          const res = decodeToken(token);

          // Expired? Reject the promise
          if (shouldRefreshToken(res.payload)) {
            reject(OAUTH_ASSISTED_EXPIRED);
            return;
          }

          debug(`-> Auth/${this.name}: message received`);

          // Resolves with then token and its decoded parts
          resolve(res);
        } catch (e) {
          // Unparseable? Reject the promise
          reject({ ...OAUTH_ASSISTED_UNREADABLE, exception: e });
        }
      };

      // It seems the message can take some time to reach the parent frame
      // or can even not reach it. In this case, we trigger a timeout.
      const timeoutId = setTimeout(() => {
        reject(OAUTH_ASSISTED_MESSAGE_TIMEOUT);
        window.removeEventListener("message", messageListener, false);
      }, MESSAGE_TIMEOUT_DURATION);

      // We're listening on messages events, and we're checking that the source can be trusted
      window.addEventListener("message", messageListener, false);
    });
  }

  // If the domains of the auth and this dashboard don't match,
  // The auth cookie won't be shared and the iframe-oauth won't work.
  get compatibleDomains(): boolean {
    const authHostname = new URL(this.authBaseUrl).hostname;
    const thisHostname = new URL(this.oauthRedirectUri).hostname;

    const authHostnameParts = authHostname.split(".").reverse().slice(0, 2);
    const thisHostnameParts = thisHostname.split(".").reverse().slice(0, 2);

    return (
      authHostnameParts[0] === thisHostnameParts[0] &&
      authHostnameParts[1] == thisHostnameParts[1]
    );
  }

  async call(rollbar: Rollbar): Promise<Response> {
    this.rollbar = rollbar;

    if (!this.compatibleDomains) {
      return Promise.reject(OAUTH_ASSISTED_INCOMPATIBLE_DOMAINS);
    }

    const attachedFrame = this.prepareIframe();

    try {
      const tokenPromise = this.getToken();

      const resetEvent = await this.runIframeNavigation(
        attachedFrame,
        "about:blank",
      );

      const target = resetEvent.target as HTMLIFrameElement;

      await this.runIframeNavigation(
        target,
        this.authIframeUrl,
        this.authBaseUrl,
      );

      await tokenPromise;

      debug(`auth: removing frame`);
      attachedFrame.remove();

      return tokenPromise;
    } catch (e) {
      // If failure because of a timeout, we broadcast it as is and let callers handle it
      if ((e as MiddlewareError).reason == "timeout") {
        throw e;
      } else {
        // If the failure is for another reason, it's probably that the user is not logged,
        // in which case we redirect the towards the auth service.
        // TODO: not sure how to do this part of the code better
        debug(`auth: background login failure !`);
        sessionStorage.setItem(ROUTE_PATH, window.location.pathname);
        window.location.assign(this.authBaseUrl);
        throw e;
      }
    }
  }
}
