<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template v-slot:buttons>
          <SCButton
            v-if="!forceTLSEnabled"
            :loading="forceTLSPending"
            @click="$emit('enableForceTLS')"
          >
            {{ $t("enable") }}
          </SCButton>
          <div v-else>
            <SCButton
              v-if="!internetAccessEnabled"
              :loading="forceTLSPending"
              @click="$emit('disableForceTLS')"
            >
              {{ $t("disable") }}
            </SCButton>
          </div>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex flex-row items-center mt-4">
        <TwoStatesIndicator
          class="mr-2"
          :isOn="forceTLSEnabled"
          :isNo="!forceTLSEnabled"
          :alternativeColors="true"
        ></TwoStatesIndicator>
        <span v-if="forceTLSEnabled">
          {{ $t("enabled") }}
        </span>
        <span v-if="!forceTLSEnabled">
          {{ $t("disabled") }}
        </span>
      </div>
      <ProTipAlert icon="title" class="mt-4">
        {{ $t("tip") }}
      </ProTipAlert>
    </template>
  </Card>
</template>

<script>
import { computed, defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import { dbFeatureIs } from "@/lib/scalingo/dbapi/database";

export default defineComponent({
  name: "ForceTLSCard",
  components: {
    Card,
    CardHeader,
    SCButton,
    TwoStatesIndicator,
    ProTipAlert,
  },
  props: {
    db: Object,
    promiseInfo: Object,
  },
  emits: ["disableForceTLS", "enableForceTLS"],

  setup(props) {
    const forceTLSEnabled = computed(() =>
      dbFeatureIs(props.db, "force-ssl", "ACTIVATED"),
    );
    const internetAccessEnabled = computed(() =>
      dbFeatureIs(props.db, "publicly-available", "ACTIVATED"),
    );
    const forceTLSPending = computed(() => {
      return (
        props.promiseInfo.isLoading ||
        dbFeatureIs(props.db, "force-ssl", "PENDING_TO_REMOVE") ||
        dbFeatureIs(props.db, "force-ssl", "PENDING_TO_ACTIVATE")
      );
    });

    return {
      forceTLSEnabled,
      forceTLSPending,
      internetAccessEnabled,
    };
  },
});
</script>

<i18n>
en:
  title: "Force TLS connections"
  subtitle: "By default, you can connect your database both with or without TLS encryption."
  enable: "Enable"
  disable: "Disable"
  enabled: "Non-TLS connections are blocked"
  disabled: "Non-TLS connections are allowed"
  tip: "Before enabling this feature be sure that your application is able to connect your database using TLS, otherwise it will be unable to connect."
fr:
  title: "Forcer les connexions TLS"
  subtitle: "Par défaut, vous pouvez vous connecter à votre base de données avec ou sans chiffrement TLS."
  enable: "Activer"
  disable: "Désactiver"
  enabled: "Les connexions sans TLS sont bloquées"
  disabled: "Les connexions sans TLS sont autorisées"
  tip: "Avant d'activer cette fonctionnalité, assurez-vous que votre application peut se connecter à votre base de données en utilisant TLS, sinon elle ne pourra pas se connecter."
</i18n>
