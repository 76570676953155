<script>
import { ref, defineComponent } from "vue";

import { currentDB, currentDBClient } from "@/stores/current/db";

export default defineComponent({
  name: "PostgresExtension",
  setup() {
    const extensionList = ref([]);

    async function getExtensionsList() {
      const client = await currentDBClient();
      const db = currentDB();
      const response = await client.Database.pgListExtensions(db.id);

      extensionList.value = response.result;
    }

    getExtensionsList();

    return {
      extensionList: extensionList,
    };
  },
});
</script>
