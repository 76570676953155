import { unpackData } from "scalingo/lib/utils";

import ScalingoDBApi from "@/lib/scalingo/dbapi/client";

export interface User {
  name: string;
  read_only: boolean;
  protected: boolean;
  dbms_attributes: DbmsAttributes;
}

export interface DbmsAttributes {
  password_encryption: string;
}

export type CreateParams = {
  database_id: string;
  name: string;
  read_only: boolean;
};

/**
 * DB Users API Client
 */
export default class Users {
  /** Scalingo DB API Client */
  _client: ScalingoDBApi;

  /**
   * Create a new "thematic" client
   * @param client Scalingo DB API Client
   */
  constructor(client: ScalingoDBApi) {
    this._client = client;
  }
  /**
   * Get list of users of a database
   * @see https://developers.scalingo.com/databases/users#list-database-users
   */
  for(addonId: string): Promise<User[]> {
    return unpackData(
      this._client.apiClient().get(`/databases/${addonId}/users`),
      "database-users",
    );
  }

  /**
   * Create a database user
   * @see https://developers.scalingo.com/databases/users#create-a-new-user
   */
  create(payload: CreateParams): Promise<User> {
    return unpackData(
      this._client.apiClient().post(`/databases/${payload.database_id}/users`, {
        database_user: payload,
      }),
      "database_user",
    );
  }

  /**
   * Delete a database user
   * @see https://developers.scalingo.com/databases/users#delete-a-user
   */
  destroy(dbId: string, userName: string): Promise<void> {
    return unpackData(
      this._client.apiClient().delete(`/databases/${dbId}/users/${userName}`),
    );
  }

  /**
   * Reset a database user password
   * @see https://developers.scalingo.com/databases/users#reset-a-user-password
   */
  reset(dbId: string, userName: string): Promise<void> {
    return unpackData(
      this._client
        .apiClient()
        .post(`/databases/${dbId}/users/${userName}/reset_password`),
      "database_user",
    );
  }
}
