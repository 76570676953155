<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <div v-if="true">
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template v-slot:buttons>
          <SCButton v-if="isEnabled" :loading="isPending" @click="disableAOF">
            {{ $t("disable") }}
          </SCButton>
          <SCButton v-else :loading="isPending" @click="enableAOF">
            {{ $t("enable") }}
          </SCButton>
        </template>
      </CardHeader>

      <div class="mt-4">
        <div class="flex flex-row items-center">
          <TwoStatesIndicator
            class="mr-2"
            :isOn="isEnabled"
            :isNo="!isEnabled"
            :alternativeColors="true"
          ></TwoStatesIndicator>
          <span v-if="isEnabled">
            {{ $t("enabled") }}
          </span>
          <span v-if="!isEnabled">
            {{ $t("disabled") }}
          </span>
        </div>
      </div>

      <div class="mt-4 text-scale-8">{{ $t("text") }}</div>
    </div>
    <EmptyCardState v-else>
      <template #icon>
        <PuzzlePieceGlyph class="h-8 text-scale-5"></PuzzlePieceGlyph>
      </template>
    </EmptyCardState>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue";

import PuzzlePieceGlyph from "@/components/atoms/glyphs/PuzzlePieceGlyph.vue";
import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import { dbFeatureIs } from "@/lib/scalingo/dbapi/database";

export default defineComponent({
  name: "AOFCard",
  components: {
    EmptyCardState,
    PuzzlePieceGlyph,
    CardHeader,
    SCButton,
    TwoStatesIndicator,
  },
  props: {
    db: Object,
    user: Object,
    promiseInfo: Object,
    enableAOF: Function,
    disableAOF: Function,
  },
  setup(props) {
    const isEnabled = computed(() =>
      dbFeatureIs(props.db, "redis-aof", "ACTIVATED"),
    );

    const isPending = computed(() => {
      return (
        props.promiseInfo.isLoading ||
        dbFeatureIs(props.db, "redis-aof", "PENDING")
      );
    });

    return {
      isPending,
      isEnabled,
    };
  },
});
</script>

<i18n>
en:
  title: "AOF Persistence"
  subtitle: "AOF persistence configuration"
  text: "This mode provides the highest level of persistency for Redis®, any command impacting the dataset will be saved synchronously in file, ensuring that in case of incident, all the data will be recoverable from this log file."
  enable: "Enable"
  disable: "Disable"
  enabled: "AOF persistence enabled"
  disabled: "AOF persistence disabled"
fr:
  title: "Persistance de l'AOF"
  subtitle: "Configuration de la persistance de l'AOF"
  text: "Ce mode fournit le plus haut niveau de persistance pour Redis®, toute commande ayant un impact sur le jeu de données sera sauvegardée de manière synchrone dans un fichier, garantissant qu'en cas d'incident, toutes les données pourront être récupérées à partir de ce fichier de log."
  enable: "Activer"
  disable: "Désactiver"
  enabled: "Persistance de l'AOF activée"
  disabled: "Persistance de l'AOF désactivée"
</i18n>
