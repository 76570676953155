import { defineStore } from "pinia";
import { computed, ref, Ref } from "vue";

import {
  defaultRegion,
  scalingoClient,
  scalingoPrivateClient,
} from "@/lib/scalingo/client";

type MenuTypes = "app" | "welcome" | "db";

export const useSessionStore = defineStore("session", () => {
  const userToken = ref<string | null>(null);

  const client = function (region: string = defaultRegion.code) {
    return scalingoClient(userToken.value as string, region);
  };

  const privateClient = function (region: string = defaultRegion.code) {
    return scalingoPrivateClient(userToken.value as string, region);
  };

  const displayBreadcrumbMenu = ref(false);

  function showBreadcrumbMenu() {
    displayBreadcrumbMenu.value = true;
  }

  function hideBreadcrumbMenu() {
    displayBreadcrumbMenu.value = false;
  }

  const displayMenu: Ref<MenuTypes[]> = ref([]);

  function showMenu(type: MenuTypes) {
    displayMenu.value.push(type);
  }

  function hideMenu() {
    displayMenu.value.pop();
  }

  const displayWelcomeMenu = computed(() => {
    return displayMenu.value[displayMenu.value.length - 1] === "welcome";
  });
  const displayAppMenu = computed(() => {
    return displayMenu.value[displayMenu.value.length - 1] === "app";
  });
  const displayDbMenu = computed(() => {
    return displayMenu.value[displayMenu.value.length - 1] === "db";
  });

  return {
    userToken,
    displayBreadcrumbMenu,
    showBreadcrumbMenu,
    hideBreadcrumbMenu,
    displayWelcomeMenu,
    displayAppMenu,
    displayDbMenu,
    showMenu,
    hideMenu,
    client,
    privateClient,
  };
});
