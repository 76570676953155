<template>
  <SCBanner kind="warning">
    <p class="ml-1 mt-0.5">
      {{ $t("maintenanceInProgress") }}
      {{ $t("text") }}
    </p>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "MaintenanceRunningBanner",
  components: { SCBanner },
  props: {
    items: Array,
    db: Object,
    dbMaintenance: Object,
  },
});
</script>

<i18n>
  en:
    maintenanceInProgress: "Maintenance in progress."
    text: "Your database will remain available, but please avoid making any changes until the operation has been completed."
  fr:
    maintenanceInProgress: "Maintenance en cours."
    text: "Votre base de données reste disponible, mais il est conseillé d’éviter toute modification tant que l’opération n’est pas terminée."
</i18n>
