/* eslint-disable @typescript-eslint/no-explicit-any */
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export interface Order {
  start_date: string;
  quote: string;
  quote_amount: number;
  status: string;
  consumed: number;
  email: string;
  end_date: string;
  message_to_user: string;
}

export interface Invoice {
  total_consumption: number;
  billing_month: string;
}

export type IndexQuery = {
  owner_id: string;
  page?: number;
  per_page?: number;
};

export type IndexResponse = {
  meta: Record<string, any>;
  orders: Order[];
};

export type InvoicesResponse = {
  meta: Record<string, any>;
  invoices: Invoice[];
};

export default class Orders {
  client: Scalingo;

  /**
   * Create new Client
   * @param {Client} client - Scalingo API Client
   */
  constructor(client: Scalingo) {
    this.client = client;
  }

  all(params: IndexQuery): Promise<IndexResponse> {
    return unpackData(
      this.client.billingApiClient().get("/orders", { params }),
    );
  }

  show(id: string): Promise<Order> {
    return unpackData(
      this.client.billingApiClient().get(`/orders/${id}`),
      "order",
    );
  }
  invoices_for_order(order_id: string): Promise<InvoicesResponse> {
    return unpackData(
      this.client.billingApiClient().get(`/orders/${order_id}/invoices`),
    );
  }
}
