<template>
  <VueLine
    :chartData="{ datasets }"
    :chartOptions="options"
    :plugins="plugins"
  />
</template>

<script>
import CrosshairPlugin from "chartjs-plugin-crosshair";
import { defineComponent } from "vue";
import { Line as VueLine } from "vue-chartjs";

import { crosshairConfig } from "@/lib/metrics";

export default defineComponent({
  name: "DiskIO",
  components: { VueLine },
  props: {
    datasets: Array,
    maxLimit: Number,
  },
  data() {
    return {
      options: {
        animation: false,
        aspectRatio: 3,
        hover: { intersect: false },
        scales: {
          x: {
            type: "time",
            bounds: "ticks",
            offset: true,
            offsetAfterAutoskip: true,
            ticks: {
              source: "auto",
              autoSkip: true,
              autoSkipPadding: 60,
            },
          },
          y: {
            offset: true,
            stacked: true,
            min: 0,
            max: this.maxLimit,
            grid: {
              drawBorder: false,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              stepSize: this.maxLimit / 2,
              callback(value) {
                return value.toFixed(0) + " io/s";
              },
            },
          },
        },
        plugins: {
          legend: {
            align: "end",
            position: "bottom",
          },
          tooltip: {
            intersect: false,
            mode: "index",
            position: "nearest",
            callbacks: {
              label(context) {
                const dataset = context.dataset;

                let label = dataset.label || "";
                if (label) {
                  label += ": ";
                }
                label += `${context.raw.y.toFixed(1)} io/s`;
                return label;
              },
            },
          },
          crosshair: crosshairConfig,
        },
      },
    };
  },
  computed: {
    plugins() {
      return [CrosshairPlugin];
    },
  },
});
</script>
