import { ActionHandler } from "@/lib/handlers/action";
import { Contact as HDSContact } from "@/lib/scalingo/contacts";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";

import type { ComponentPublicInstance } from "vue";

export class TouchHdsContactHandler extends ActionHandler<HDSContact> {
  keyPath = "hdsContact.touch";

  constructor(
    component: ComponentPublicInstance,
    readonly contact: HDSContact,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    const store = usePointOfContactStore();

    this.followPinia(store.update({}));
  }
}
