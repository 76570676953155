export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview of your database configuration and health"])},
        "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk"])},
        "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
        "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
        "forceTls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS"])},
        "ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redundancy"])},
        "periodicBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled backups"])},
        "internetAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet access"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "singleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single-node"])},
        "multiNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-nodes"])},
        "backupAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At ", _interpolate(_named("hour")), ":00 UTC"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database connections"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
        "redis": {
          "rdb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB"])},
          "aof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF"])},
          "cacheMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache mode"])}
        },
        "hover": {
          "swap": {
            "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap usage seems high, check your memory and swap consumption"])},
            "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap level is high, this could alter your application performance"])}
          },
          "cpu": {
            "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU level is high, this could alter your application performance"])},
            "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU level is very high, this could alter your application performance"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "storage": {
            "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of the included storage is used. Over-usage will be charged additionally."])}
          },
          "nodes": {
            "multiNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your database is highly available (HA) and tolerant to primary node failure."])},
            "singleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change to a Business plan and enjoy increased redundancy and a 99.96% SLA."])}
          },
          "tls": {
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-TLS connections are allowed"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-TLS connections are blocked"])}
          },
          "backups": {
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last successful backup was less than 24 hours ago"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new scheduled backup will be made"])}
          },
          "access": {
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning: internet access to your database is enabled"])}
          }
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de la configuration et de l'état de votre base de données"])},
        "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mémoire"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disque"])},
        "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
        "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
        "forceTls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS"])},
        "ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redondance"])},
        "periodicBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups planifiés"])},
        "internetAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à internet"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
        "singleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mono-noeud"])},
        "multiNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-noeuds (HA)"])},
        "backupAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A ", _interpolate(_named("hour")), ":00 UTC"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions à la base de données"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée"])},
        "redis": {
          "rdb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB"])},
          "aof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF"])},
          "cacheMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache mode"])}
        },
        "hover": {
          "swap": {
            "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation du swap semble élevée, vérifiez votre consommation de mémoire et de swap"])},
            "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le niveau de swap est élevé, cela pourrait altérer les performances de votre application"])}
          },
          "cpu": {
            "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le niveau du CPU est élevé, ce qui peut altérer les performances de votre application."])},
            "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le niveau du CPU est très élevé, ce qui peut altérer les performances de votre application."])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
          },
          "storage": {
            "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout le stockage inclus est utilisé. La sur-utilisation sera facturée en supplément."])}
          },
          "nodes": {
            "multiNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre base de données est hautement disponible (HA) et tolérante à la panne du nœud primaire."])},
            "singleNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changez pour un plan Business et profitez d’une redondance accrue ainsi que d’un SLA de 99,96 %."])}
          },
          "tls": {
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les connexions non TLS sont autorisées"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les connexions non TLS sont bloquées"])}
          },
          "backups": {
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier backup réussi date de moins de 24 heures"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nouveau backup planifié"])}
          },
          "access": {
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement : l'accès internet à votre base de données est activé"])}
          }
        }
      }
    }
  })
}
