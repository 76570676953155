<template>
  <div
    v-if="addons.promiseInfo"
    class="bg-scale-0 rounded px-8 py-6 flex flex-col"
  >
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :to="routeArgs">
          {{ $t("action") }}
        </SCButton>
      </template>
    </CardHeader>

    <template v-if="addons.promiseInfo.isLoading">
      <LoadingCardState />
    </template>

    <template v-if="addons.promiseInfo.isFinished">
      <div v-if="addons.items.length" class="flex-grow">
        <div
          v-for="(item, index) in addons.items"
          :key="index"
          class="py-3 flex"
          :class="{ 'border-t border-scale-2': index > 0 }"
        >
          <div class="flex flex-grow pv:items-center flex-col pv:flex-row">
            <div class="flex items-center">
              <div class="pr-2 self-center">
                <AddonIcon :addon="item" :width="22" />
              </div>
              <div class="font-medium">
                {{ item.addon_provider.name }}
              </div>
            </div>

            <div class="text-scale-5 pl-1">({{ item.plan.display_name }})</div>
          </div>

          <SCButton
            v-if="item.status == 'running'"
            kind="neutral"
            class="ml-3"
            :actions="newDashActions(item)"
            @click="openDashboard(item, index)"
          >
            {{ $t("dashboard.old") }}

            <font-awesome-icon
              class="ml-1"
              icon="external-link-alt"
            ></font-awesome-icon>
          </SCButton>
          <SCButton
            v-else-if="item.status === 'provisioning'"
            kind="neutral"
            class="ml-3"
            loading
          >
            {{ $t("dashboard.old") }}

            <font-awesome-icon
              class="ml-1"
              icon="external-link-alt"
            ></font-awesome-icon>
          </SCButton>

          <a ref="addon-link" class="hidden" href="#" target="_blank"></a>
        </div>
      </div>
      <EmptyCardState v-else>
        <template #icon>
          <PuzzlePieceGlyph class="h-8 text-scale-5"></PuzzlePieceGlyph>
        </template>

        {{ $t("none") }}
      </EmptyCardState>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PuzzlePieceGlyph from "@/components/atoms/glyphs/PuzzlePieceGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AddonIcon from "@/components/organisms/addons/Icon.vue";
import { scalingoClient } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";

export default defineComponent({
  name: "AddonsCard",
  components: {
    AddonIcon,
    EmptyCardState,
    SCButton,
    LoadingCardState,
    PuzzlePieceGlyph,
    CardHeader,
  },
  props: {
    app: Object,
    user: Object,
    addons: Object,
  },
  data() {
    return {
      routeArgs: {
        name: Routes.App.Resources,
        args: { id: this.app.name },
      },
    };
  },
  methods: {
    async openDashboard(addon, index) {
      let addonResponse = await scalingoClient(
        this.$store,
        this.app.region,
      ).Addons.sso(addon.app_id, addon.id);

      const target = this.$refs["addon-link"][index];

      target.setAttribute("href", addonResponse.sso_url);
      target.click();
      target.setAttribute("href", "#");
    },
    newDashActions(addon) {
      if (["redis", "postgresql"].includes(addon.addon_provider.id)) {
        return [
          {
            text: this.$t("dashboard.new"),
            to: {
              name: Routes.App.Db.Root,
              params: {
                region: this.app.region,
                id: this.app.name,
                dbId: addon.addon_provider.id,
              },
            },
          },
        ];
      } else if (
        [""].includes(addon.addon_provider.id) &&
        userIsAdminOrHasFlag(this.user, "employee")
      ) {
        return [
          {
            text: this.$t("dashboard.new"),
            to: {
              name: Routes.App.Db.Root,
              params: {
                region: this.app.region,
                id: this.app.name,
                dbId: addon.addon_provider.id,
              },
            },
          },
        ];
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Addons"
  subtitle: "Linked extensions."
  action: "Manage"
  dashboard:
    new: "New version (preview)"
    old: "Dashboard"
  none: "No addon yet"
fr:
  title: "Addons"
  subtitle: "Extensions liées."
  action: "Gérer"
  dashboard:
    new: "Nouvelle version (preview)"
    old: "Dashboard"
  none: "Aucun addon"
</i18n>
