<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :addon="addon"
    :db="db"
    :dbVersion="dbVersion"
    :dbPlan="dbPlan"
    :lastBackup="lastBackup"
    :oldestBackup="oldestBackup"
    :dbMetrics="dbMetrics"
    :variables="variables"
    :upgradeCtx="upgradeCtx"
    @upgradeDB="upgradeDB"
    @startDBUpgrade="startDBUpgrade"
    @cancelDBUpgrade="cancelDBUpgrade"
  ></ViewComponent>
</template>

<script>
import { ref, computed, defineComponent, onBeforeMount, nextTick } from "vue";
import { useStore } from "vuex";

import ViewComponent from "@/components/views/db/DbOverview.vue";
import { closeCurrentModal } from "@/lib/modals";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { ensureVariables, listVariables } from "@/store/variables";
import { useCurrentAppStore } from "@/stores/current/app";
import {
  useCurrentDBStore,
  currentDBClient,
  currentDB,
} from "@/stores/current/db";
import { useDbBackupsStore } from "@/stores/db/backups";
import { useDbMetricsStore } from "@/stores/db/metrics";
import { useDbPlanStore } from "@/stores/db/plan";
import { useDbVersionStore } from "@/stores/db/version";

export default defineComponent({
  name: "DbOverviewContainer",
  components: { ViewComponent },
  setup() {
    const store = useStore();
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const dbVersionStore = useDbVersionStore();
    const dbBackupsStore = useDbBackupsStore();
    const dbMetricsStore = useDbMetricsStore();
    const dbPlanStore = useDbPlanStore();
    const upgradeCtx = ref(null);

    onBeforeMount(() => {
      dbBackupsStore.ensure();
      dbMetricsStore.ensure({ staleAfter: "always" });
      onBeforeMount(() => dbPlanStore.ensure());
      ensureVariables(store, { staleAfter: "always" });
    });

    async function upgradeDB() {
      const client = await currentDBClient();
      const db = currentDB();
      const promise = client.Database.upgradeDatabase(db.id)
        .then((r) => r.operation.wait(2000))
        .then(() => currentDbStore.fetchDatabase())
        .then(() => dbMetricsStore.ensure({ staleAfter: "always" }));

      upgradeCtx.value.info = promiseInfo(promise);

      await promise;
      await nextTick();

      closeCurrentModal();
    }

    function startDBUpgrade({ db, dbVersion }) {
      upgradeCtx.value = {
        info: voidPromiseInfo(),
        db,
        dbVersion,
      };
    }

    function cancelDBUpgrade() {
      upgradeCtx.value = null;
    }

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
      addon: computed(() => currentDbStore.addon),
      upgradeCtx,
      upgradeDB,
      startDBUpgrade,
      cancelDBUpgrade,

      dbVersion: computed(() => {
        return {
          item: dbVersionStore.item,
          promiseInfo: dbVersionStore.promiseInfo,
        };
      }),
      dbMetrics: computed(() => {
        return {
          item: dbMetricsStore.item,
          promiseInfo: dbMetricsStore.promiseInfo,
        };
      }),
      dbPlan: computed(() => {
        return {
          item: dbPlanStore.item,
          promiseInfo: dbPlanStore.promiseInfo,
        };
      }),
      variables: computed(() => {
        return listVariables(store, {
          sortBy: "name",
          sortDirection: "asc",
        });
      }),
      lastBackup: computed(() => {
        const filteredItems = filterItems(dbBackupsStore.items, {
          limit: 1,
          sortBy: "created_at",
          sortDirection: "desc",
        });
        return {
          item: filteredItems?.[0],
          promiseInfo: dbBackupsStore.promiseInfo,
        };
      }),
      oldestBackup: computed(() => {
        const filteredItems = filterItems(dbBackupsStore.items, {
          limit: 1,
          sortBy: "created_at",
          sortDirection: "asc",
          transform: (backups) => backups.filter((b) => b.status === "done"),
        });

        return {
          item: filteredItems?.[0],
          promiseInfo: dbBackupsStore.promiseInfo,
        };
      }),
    };
  },
});
</script>
