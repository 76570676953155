export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "maintenanceInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance in progress."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your database will remain available, but please avoid making any changes until the operation has been completed."])}
      },
      "fr": {
        "maintenanceInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance en cours."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre base de données reste disponible, mais il est conseillé d’éviter toute modification tant que l’opération n’est pas terminée."])}
      }
    }
  })
}
