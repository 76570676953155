<template>
  <div class="bg-scale-0">
    <div class="container px-4">
      <div class="flex py-4 border-t border-scale-2">
        <PageMenu :items="menuItems"></PageMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PageMenu from "@/components/molecules/menus/PageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DbMenu",
  components: { PageMenu },
  props: {
    app: Object,
    db: Object,
    selection: String,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      if (!this.app) return [];
      if (!this.db) return [];

      const routerParams = {
        region: this.app.region,
        id: this.app.name,
        dbId: this.db.type_name,
      };

      let menuItems = [
        {
          name: this.$t("overview"),
          key: Routes.Db.Overview,
          routerArgs: { name: Routes.Db.Overview, params: routerParams },
        },
        {
          name: this.$t("logs"),
          key: Routes.Db.Logs.Root,
          routerArgs: { name: Routes.Db.Logs.Root, params: routerParams },
        },
        {
          name: this.$t("metrics"),
          key: Routes.Db.Metrics,
          routerArgs: { name: Routes.Db.Metrics, params: routerParams },
        },
        {
          name: this.$t("backups"),
          key: Routes.Db.Backups.Root,
          routerArgs: { name: Routes.Db.Backups.Root, params: routerParams },
        },
        {
          name: this.$t("settings"),
          key: Routes.Db.Settings,
          routerArgs: { name: Routes.Db.Settings, params: routerParams },
        },
      ];

      if (this.db.status !== "running") {
        menuItems = [
          {
            name: this.$t("logs"),
            key: Routes.Db.Logs.Root,
            routerArgs: { name: Routes.Db.Logs.Root, params: routerParams },
          },
          {
            name: this.$t("metrics"),
            key: Routes.Db.Metrics,
            routerArgs: { name: Routes.Db.Metrics, params: routerParams },
          },
        ];
      }

      if (this.db.type_name === "postgresql") {
        let backupIndex = menuItems.findIndex(
          (item) => item.name === this.$t("backups"),
        );

        menuItems.splice(backupIndex, 0, {
          name: this.$t("queryStatistics"),
          key: Routes.Db.QueryStatistics,
          routerArgs: { name: Routes.Db.QueryStatistics, params: routerParams },
        });
        menuItems.splice(backupIndex, 0, {
          name: this.$t("runningQueries"),
          key: Routes.Db.RunningQueries,
          routerArgs: { name: Routes.Db.RunningQueries, params: routerParams },
        });
      }

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.key)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    overview: "Overview"
    logs: "Logs"
    metrics: "Metrics"
    backups: "Backups"
    settings: "Settings"
    queryStatistics: "Query Statistics"
    runningQueries: "Running Queries"
  fr:
    overview: "Vue générale"
    logs: "Logs"
    metrics: "Métriques"
    backups: "Backups"
    settings: "Paramètres"
    queryStatistics: "Statistiques des requêtes"
    runningQueries: "Requêtes en cours d'exécution"
</i18n>
