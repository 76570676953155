export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
        "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])}
      },
      "fr": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue générale"])},
        "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
        "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])}
      }
    }
  })
}
