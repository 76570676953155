import { unpackData } from "scalingo/lib/utils";

import ScalingoDBApi from "@/lib/scalingo/dbapi/client";
import { DbInstance } from "@/lib/scalingo/dbapi/instances";
import { DbOperation } from "@/lib/scalingo/dbapi/operation";

export type GenericDbFeature = "force-ssl" | "publicly-available";
export type RedisFeature = "redis-rdb" | "redis-aof" | "redis-cache";
export type DbFeatureName = GenericDbFeature | RedisFeature;
export type FeatureStatus =
  | "PENDING"
  | "ACTIVATED"
  | "FAILED"
  | "PENDING_TO_ACTIVATE"
  | "PENDING_TO_REMOVE";

export interface DbFeature {
  name: DbFeatureName;
  status: FeatureStatus;
  message?: string;
}

export interface DbTypeVersion {
  id: string;
  created_at: string;
  major: number;
  minor: number;
  patch: number;
  build: number;
  database_type_id: string;
  updated_at: string;
  next_upgrade: DbTypeVersion | null;
  features: string[];
  database_type_name: null | string;
  allowed_plugins: null;
}

export interface DbPlan {
  backup: boolean;
  manual_backups_count: number;
  daily_scheduled_backups: number;
  weekly_scheduled_backups: number;
  monthly_scheduled_backups: number;
  disk: number;
  memory: number;
  minimum_version: null | string;
  es_master_data_nodes: number;
  mongo_data_node: number;
  mongo_arbiter_node: number;
  mongo_backup_node: number;
  mysql_nodes: number;
  postgresql_nodes: number;
  redis_nodes: number;
  sentinel_nodes: number;
  gateway_nodes: number;
  id: string;
}

export interface Db {
  id: string;
  resource_id: string;
  app_name: string;
  created_at: string;
  encryption_at_rest: boolean;
  features: DbFeature[];
  plan: string;
  status: string;
  type_id: string;
  type_name: string;
  version_id: string;
  mongo_repl_set_name: string;
  instances: DbInstance[];
  next_version_id: null | string;
  readable_version: string;
  hostname: string;
  current_operation_id: null | string;
  cluster: boolean;
  periodic_backups_enabled: boolean;
  periodic_backups_scheduled_at: unknown;
  first_pitr_backup: null;
  postgresql_config: unknown;
  flags: string[];
}

export interface DbMaintenance {
  id: string;
  database_id: string;
  type: string;
  status: string;
  started_at: string;
  ended_at: string;
}

export interface DbMemoryMetrics {
  /** Total RAM bytes used (bytes) */
  memory: number;
  /** Highest memory usage recorded (bytes) */
  memory_max: number;
  /** Max memory allocated (bytes) */
  memory_limit: number;
  /** Total SWAP bytes used (bytes) */
  swap: number;
  /** Highest swap usage recorded (bytes) */
  swap_max: number;
  /** Max SWAP allocated (bytes) */
  swap_limit: number;
}

export interface DbMetrics {
  cpu_usage: number;
  real_disk_size: number;
  memory: DbMemoryMetrics;
  instances_metrics: unknown; // unused for now
}

export interface DbPlan {
  backup: boolean;
  manual_backups_count: number;
  daily_scheduled_backups: number;
  weekly_scheduled_backups: number;
  monthly_scheduled_backups: number;
  disk: number;
  memory: number;
  minimum_version: string | null;
  es_master_data_nodes: number;
  mongo_data_node: number;
  mongo_arbiter_node: number;
  mongo_backup_node: number;
  mysql_nodes: number;
  postgresql_nodes: number;
  redis_nodes: number;
  sentinel_nodes: number;
  gateway_nodes: number;
  id: string;
}

/**
 * Database API Client
 */
export default class Database {
  /** Scalingo DB API Client */
  _client: ScalingoDBApi;

  /**
   * Create a new "thematic" client
   * @param client Scalingo DB API Client
   */
  constructor(client: ScalingoDBApi) {
    this._client = client;
  }

  /**
   * Retrieve a DB API database (different from a regional API addon)
   * @see https://developers.scalingo.com/databases/databases#retrieve-database-information
   * @param dbId ID of the addon matching the database
   */
  for(dbId: string): Promise<Db> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}`),
      "database",
    );
  }

  /**
   * Retrieve the current version informations of the db
   * @see https://developers.scalingo.com/databases/database_type_versions
   * @param dbId ID of the addon matching the database
   */
  versionInfo(dbId: string): Promise<DbTypeVersion> {
    return unpackData(
      this._client.apiClient().get(`/database_type_versions/${dbId}`),
      "database_type_version",
    );
  }

  /**
   * Retrieve the database current metrics.
   * Not the same as the instances metrics, used for the graphs
   * @see https://developers.scalingo.com/databases/metrics#get-database-metrics
   * @param dbId ID of the addon matching the database
   */
  dbMetrics(dbId: string): Promise<DbMetrics> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}/metrics`),
    );
  }

  /**
   * Retrieve the current plan informations of the db
   * @see https://developers.scalingo.com/databases/plan
   * @param dbId ID of the addon matching the database
   */
  planFor(dbId: string): Promise<DbPlan> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}/plan`),
      "plan",
    );
  }

  /**
   * Retrieve db's maintenance info
   * @see https://developers.scalingo.com/databases/maintenance#list-database-maintenance
   * @param dbId ID of the addon matching the database
   */
  maintenanceFor(dbId: string): Promise<DbMaintenance[]> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}/maintenance`),
      "maintenance",
    );
  }

  /**
   * Enable database features
   * @see https://developers.scalingo.com/databases/databases#enable-feature
   * @param dbId ID of the addon/database
   */
  enableFeature(dbId: string, name: DbFeatureName): Promise<DbFeature> {
    return unpackData(
      this._client
        .apiClient()
        .post(`/databases/${dbId}/features`, { feature: { name } }),
    );
  }

  /**
   * Disable database features
   * @see https://developers.scalingo.com/databases/databases#enable-feature
   * @param dbId ID of the addon/database
   */
  disableFeature(
    dbId: string,
    name: DbFeatureName,
  ): Promise<{ message: string }> {
    return unpackData(
      this._client
        .apiClient()
        .delete(`/databases/${dbId}/features`, { params: { feature: name } }),
    );
  }

  /**
   * Download CA Certificate
   */
  downloadCaCertificate(): void {
    const url = this._client._apiUrl + "/api/ca_certificate";
    window.location.href = url;
  }

  /**
   * Update database
   * @see https://developers.scalingo.com/databases/databases#update-a-database
   * @param dbId ID of the addon/database
   */
  updateDatabase(dbId: string, database: object): Promise<{ message: string }> {
    return unpackData(
      this._client.apiClient().put(`/databases/${dbId}`, { database }),
    );
  }

  /**
   * List running queries
   * @see https://developers.scalingo.com/databases/databases#postgresql-running-queries-management
   * @param dbId ID of the addon/database
   */
  pgListQueries(dbId: string): Promise<{ message: string }> {
    return unpackData(
      this._client
        .apiClient()
        .post(`/databases/${dbId}/action`, { action_name: "pg-list-queries" }),
    );
  }

  /**
   * List running queries
   * @see https://developers.scalingo.com/databases/databases#postgresql-running-queries-management
   * @param dbId ID of the addon/database
   */
  pgCancelQuery(dbId: string, pid: string): Promise<{ message: string }> {
    return unpackData(
      this._client.apiClient().post(`/databases/${dbId}/action`, {
        action_name: "pg-cancel-query",
        params: {
          pid: pid,
        },
      }),
    );
  }

  /**
   * List running queries
   * @see https://developers.scalingo.com/databases/databases#postgresql-running-queries-management
   * @param dbId ID of the addon/database
   */
  pgTerminateQuery(dbId: string, pid: string): Promise<{ message: string }> {
    return unpackData(
      this._client.apiClient().post(`/databases/${dbId}/action`, {
        action_name: "pg-terminate-query",
        params: {
          pid: pid,
        },
      }),
    );
  }

  /**
   * List extensions
   * @see https://developers.scalingo.com/databases/databases#postgresql-extensions-management
   * @see https://developers.scalingo.com/databases/databases#actions-over-database-management-system
   * @param dbId ID of the addon/database
   */
  pgListExtensions(
    dbId: string,
  ): Promise<{ result: never[]; message: string }> {
    return unpackData(
      this._client
        .apiClient()
        .post(`/databases/${dbId}/action`, { action_name: "list-extensions" }),
    );
  }

  /**
   * Activate PG Query Stats
   * @see https://developers.scalingo.com/databases/databases#postgresql-query-stats
   * @param dbId ID of the addon/database
   */
  pgActivateQueryStatistics(dbId: string): Promise<{ message: string }> {
    return unpackData(
      this._client.apiClient().post(`/databases/${dbId}/action`, {
        action_name: "pg-stat-statements-enable",
      }),
    );
  }

  /**
   * Get PG Query Stats
   * @see https://developers.scalingo.com/databases/databases#postgresql-query-stats
   * @param dbId ID of the addon/database
   */
  pgListQueriesStatistics(dbId: string): Promise<{ message: string }> {
    return unpackData(
      this._client.apiClient().post(`/databases/${dbId}/action`, {
        action_name: "pg-stat-statements-list",
      }),
    );
  }

  /**
   * Reset Query Stats
   * @see https://developers.scalingo.com/databases/databases#postgresql-query-stats
   * @param dbId ID of the addon/database
   */
  pgResetQueriesStatistics(dbId: string): Promise<{ message: string }> {
    return unpackData(
      this._client.apiClient().post(`/databases/${dbId}/action`, {
        action_name: "pg-stat-statements-reset",
      }),
    );
  }

  /**
   * Upgrade database
   * @see https://developers.scalingo.com/databases/databases#upgrade-a-database
   * @param dbId ID of the addon/database
   */
  async upgradeDatabase(
    dbId: string,
  ): Promise<{ upgrade: unknown; operation?: DbOperation }> {
    const result = await unpackData(
      this._client.apiClient().post(`/databases/${dbId}/upgrade`),
      undefined,
      { hasOperation: true },
    );

    if (result.operation) {
      const operation = new DbOperation(this._client, result.operation);
      await operation.refresh();
      return { upgrade: result.data, operation: operation };
    } else {
      return { upgrade: result.data };
    }
  }
}

/** Check the state of a db feature */
export function dbFeatureIs(
  db: Db,
  name: DbFeatureName,
  state: FeatureStatus,
): boolean {
  const feature = db.features.find((f) => f.name === name);

  if (!feature) return false;

  return feature.status === state;
}
