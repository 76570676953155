<template>
  <div v-if="isAuthenticated" id="app-root" :class="mainClasses">
    <div class="min-h-screen">
      <BillingStatusBanner
        :user="currentUser"
        :billingProfile="billingProfile.value"
        :isCollaboratorOnly="isCollaboratorOnly"
      />

      <TopBar
        :user="currentUser"
        :appInfos="appInfosForSearch"
        @signOutClicked="logout"
        @topInputUpdated="topInputUpdated"
        @topInputSelected="topInputSelected"
      />

      <BreadcrumbHeader
        v-if="sessionStore.displayBreadcrumbMenu"
        :user="currentUser"
        :appInfo="currentAppInfo"
        :dbInfo="currentDbInfo"
        :regions="regions"
      />

      <WelcomeMenu v-if="sessionStore.displayWelcomeMenu" />
      <AppMenu v-if="sessionStore.displayAppMenu" :app="currentAppInfo.app" />
      <DbMenu
        v-if="sessionStore.displayDbMenu"
        :app="currentAppInfo.app"
        :db="currentDbInfo.db"
      />

      <router-view />
    </div>

    <Footer
      :contract="latestPlatformContract"
      :platformStatus="platformStatus"
    />
    <Notifications
      :notifications="notifications"
      @notificationClosed="notificationClosed"
    ></Notifications>

    <UnauthorizedModal
      v-if="unauthorizedAPICall"
      @reloadRequested="reloadRequested"
    />
  </div>
</template>

<script>
import { requiredForPlatform } from "scalingo/lib/Contracts";
import { defineComponent } from "vue";

import Notifications from "@/components/molecules/notifications/Notifications.vue";
import BillingStatusBanner from "@/components/organisms/banners/BillingStatusBanner.vue";
import Footer from "@/components/organisms/footer/Footer.vue";
import TopBar from "@/components/organisms/header/Header.vue";
import BreadcrumbHeader from "@/components/organisms/headers/BreadcrumbHeader.vue";
import AppMenu from "@/components/organisms/menus/AppMenu.vue";
import DbMenu from "@/components/organisms/menus/DbMenu.vue";
import WelcomeMenu from "@/components/organisms/menus/ProfileMenu.vue";
import UnauthorizedModal from "@/components/parts/modals/UnauthorizedModal.vue";
import {
  initialLoginPipeline,
  logoutPipeline,
  refreshTokenPipeline,
} from "@/lib/auth";
import { shouldRefreshToken } from "@/lib/auth/utils";
import { eventBus } from "@/lib/events/bus";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import { availableRegions } from "@/lib/scalingo/client";
import {
  setupPlatformStatusPolling,
  platformStatus,
} from "@/lib/scalingo/feeds/status.ts";
import { dashboard } from "@/lib/utils/log";
import { Routes } from "@/router/names";
import { navErrorHandler } from "@/router/utils";
import {
  billingProfileAndFetch,
  ensureBillingProfile,
} from "@/store/billing-profile";
import { ensureContracts, listContracts } from "@/store/contracts";
import { Session, changeLocale, userToken } from "@/store/session";
import { User } from "@/store/user";
import { useAppInfosStore } from "@/stores/app-infos";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";
import { useSessionStore } from "@/stores/session";
import { useToastsStore } from "@/stores/toasts";

import { useUserStore } from "./stores/user";

const profileUrl = `${process.env.VUE_APP_AUTH_BASE_URI}/users/profile`;
const CHECK_TOKEN_INTERVAL = 5000;

export default defineComponent({
  components: {
    BillingStatusBanner,
    Notifications,
    Footer,
    UnauthorizedModal,
    TopBar,
    BreadcrumbHeader,
    WelcomeMenu,
    AppMenu,
    DbMenu,
  },
  setup() {
    const sessionStore = useSessionStore();
    const appInfosStore = useAppInfosStore();
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const toastsStore = useToastsStore();

    return {
      sessionStore,
      currentAppStore,
      currentDbStore,
      appInfosStore,
      toastsStore,
    };
  },
  data() {
    return {
      tokenRefreshIntervalId: null,
      authResponse: null,
      systemThemePreference: null,
      unauthorizedAPICall: false,
      regions: availableRegions,
    };
  },
  computed: {
    platformStatus() {
      return platformStatus;
    },
    currentAppInfo() {
      return {
        isSet: this.currentAppStore.isSet,
        app: this.currentAppStore.appInfoOrFull,
        regional: this.currentAppStore.regional,
      };
    },
    currentDbInfo() {
      return {
        isSet: this.currentDbStore.isSet,
        addon: this.currentDbStore.addon,
        db: this.currentDbStore.database,
      };
    },
    currentAddon() {
      return this.currentDbStore.item;
    },
    mainClasses() {
      let classes = `text-scale-10 `;

      if (this.localDevelopment) classes += "local-background";
      else if (this.staging) classes += "staging-background";
      else classes += "bg-scale-1";

      return classes;
    },

    localDevelopment() {
      return process.env.NODE_ENV === "development";
    },
    staging() {
      return (
        process.env.NODE_ENV === "production" &&
        process.env.VUE_APP_PLATFORM_ENV === "staging"
      );
    },
    notifications() {
      return this.toastsStore.items;
    },
    billingProfile() {
      return billingProfileAndFetch(this.$store);
    },
    isAuthenticated() {
      return !!this.currentUser;
    },
    appInfosForSearch() {
      const { items, promiseInfo, none } = this.appInfosStore;

      const sortedItems = filterItems(items, {
        sortBy: "_isReviewApp",
        sortDirection: "asc",
      });

      return { items: sortedItems, promiseInfo, none };
    },
    isCollaboratorOnly() {
      try {
        const ownerIds = this.appInfosForSearch.items.map(
          (item) => item.owner.id,
        );

        // Checks if user has applications but doesn't own any of them
        return !ownerIds.includes(this.currentUser.id) && ownerIds.length > 0;
      } catch {
        return false;
      }
    },
    userToken() {
      return userToken(this.$store);
    },
    latestPlatformContract() {
      const contractItems = listContracts(this.$store).items;
      return contractItems.find((c) => requiredForPlatform(c) && c.latest);
    },
  },
  watch: {
    "$root.appTheme": {
      immediate: true,
      handler(newVal, oldVal) {
        const node = document.querySelector("body");

        node.classList.remove(oldVal);
        node.classList.add(newVal);
      },
    },
    "currentUser.preferences.locale": {
      handler(newVal) {
        if (newVal) {
          changeLocale(this.$i18n, newVal);
        }
      },
    },
    "currentUser.preferences.theme": {
      immediate: true,
      handler(newVal) {
        this.$root.userTheme = newVal;
      },
    },
    currentUser: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (!newVal.profile_valid) {
            window.location = profileUrl;
          }
        }
      },
    },
    $route() {
      window.Intercom?.("update");
    },
  },
  async created() {
    setupPlatformStatusPolling();

    this.setupEventBusListeners();

    this.$initialLogin = initialLoginPipeline(
      this.$rollbar,
      this.onInitialLoginSuccess,
    );

    this.$refreshToken = refreshTokenPipeline(
      this.$rollbar,
      this.onTokenRefreshSuccess,
    );

    this.$initialLogin.call();
  },
  mounted() {
    const app_id = process.env.VUE_APP_INTERCOM_APP_ID;
    if (!app_id) return;

    window.Intercom?.("boot", { app_id });
  },
  methods: {
    logout() {
      this.$store.dispatch(Session.actions.LOGOUT);
      window.Intercom?.("shutdown");

      logoutPipeline(this.$rollbar).call();
    },
    onInitialLoginSuccess(authResponse) {
      this.onTokenRefreshSuccess(authResponse);

      ensureBillingProfile(this.$store);
      ensureContracts(this.$store);

      this.appInfosStore.ensure();

      if (authResponse.attemptedRoute) {
        this.$router.push(authResponse.attemptedRoute).catch((e) => {
          if (e.name !== "NavigationDuplicated") {
            throw e;
          }
        });
      }

      return authResponse;
    },
    onTokenRefreshSuccess(authResponse) {
      this.authResponse = authResponse;

      this.$store.dispatch(Session.actions.LOGIN, authResponse.token);
      this.fetchCurrentUser();
      this.setTokenRefresh();

      return authResponse;
    },
    topInputUpdated(userQuery) {
      if (this.$route.name === Routes.Projects) {
        let query = { ...this.$route.query };

        if (userQuery && userQuery.length) {
          query.appName = userQuery;
        } else {
          delete query.appName;
        }

        this.$router.push({ query });
      }
    },
    topInputSelected(eventArgs) {
      let targetRoute = Routes.App.Root;

      const sticky = this.currentUser.preferences?.sticky_search;
      const appSubpage = this.$route.matched.find(
        (r) => r.name === Routes.App.Root,
      );
      const addonSubPage = this.$route.matched.find(
        (r) =>
          r.name === Routes.App.Addon.Creation.Root ||
          r.name === Routes.App.Addon.Edition.Root,
      );

      const notifierSubPage = this.$route.matched.find(
        (r) =>
          r.name === Routes.App.Notifiers.Show ||
          r.name === Routes.App.Notifiers.Edit.Infos ||
          r.name === Routes.App.Notifiers.Edit.Events,
      );

      if (sticky && appSubpage) {
        // Some subresources need to be rewritten
        if (this.$route.name === Routes.App.Notifiers.Show) {
          targetRoute = Routes.App.Notifiers.Root;
        } else if (this.$route.name === Routes.App.Deploy.List.Show) {
          targetRoute = Routes.App.Deploy.List.Root;
        } else if (this.$route.name === Routes.App.Domains.Show) {
          targetRoute = Routes.App.Domains.Root;
        } else if (addonSubPage) {
          targetRoute = Routes.App.Resources;
        } else if (notifierSubPage) {
          targetRoute = Routes.App.Notifiers.List;
        } else {
          targetRoute = this.$route.name;
        }
      }

      this.$router
        .push({
          name: targetRoute,
          params: { id: eventArgs.name, region: eventArgs.region },
        })
        .catch(navErrorHandler);
    },
    notificationClosed(notification) {
      useToastsStore().closeOne(notification);
    },
    async fetchCurrentUser() {
      // Getting the user, waiting the operation to finish
      this.$store.dispatch(User.actions.REFRESH);
      const userFetchOperation = this.$store.getters[User.getters.LATEST_FETCH];
      await userFetchOperation.settled;

      const userStore = useUserStore();
      userStore.item = this.currentUser;

      this.updateIntercom(this.currentUser);

      // Setting the appropriate locale
      const { locale } = this.currentUser.preferences;
      changeLocale(this.$i18n, locale);
    },
    async setTokenRefresh() {
      if (!this.tokenRefreshIntervalId) {
        this.tokenRefreshIntervalId = setInterval(() => {
          if (shouldRefreshToken(this.authResponse.payload)) {
            this.$refreshToken.call();
          }
        }, CHECK_TOKEN_INTERVAL);
      }
    },
    updateIntercom(user) {
      // According to rollbar, this case happens. Probably a race/network related condition.
      if (!user) return;

      if (user.flags["disable-in-app-support"]) {
        dashboard.log("disabling intercom for current user due to flag");
        window.Intercom?.("shutdown");
        return;
      }

      window.Intercom?.("update", {
        user_id: user.id,
        email: user.email,
        name: user.fullname,
        user_hash: user.intercom_hash,
        created_at: user.created_at,
      });
    },
    setupEventBusListeners() {
      eventBus.on("scalingoAPIUnauthorized", (error) => {
        this.unauthorizedAPICall = true;
        this.$rollbar.error(error);
      });
    },
    reloadRequested() {
      window.location.reload();
    },
  },
});
</script>

<style scoped>
.local-background {
  background-image: linear-gradient(
    45deg,
    var(--scale-0) 6.52%,
    var(--scale-1) 6.52%,
    var(--scale-1) 50%,
    var(--scale-0) 50%,
    var(--scale-0) 56.52%,
    var(--scale-1) 56.52%,
    var(--scale-1) 100%
  );
  background-size: 130.11px 130.11px;
}

.staging-background {
  background-image: linear-gradient(
    90deg,
    var(--scale-0) 6.52%,
    var(--scale-1) 6.52%,
    var(--scale-1) 50%,
    var(--scale-0) 50%,
    var(--scale-0) 56.52%,
    var(--scale-1) 56.52%,
    var(--scale-1) 100%
  );
  background-size: 184px 184px;
}
</style>
